.tag-input {
    padding: .7rem;
    border-radius: 12px;
    border: 1px solid #C7CBD1;
}

.tag-input::placeholder {
    font-size: .9rem;
}

.react-tagsinput-input {
    font-size: .9rem;
}

.react-tagsinput-tag {
    background-color: #FFECE5;
    border-color: #FF4105;
    color: #000;
    border-radius: 4px;
}