.container {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.screenContainer {
    width: 100%;
    height: calc(100vh - calc(150 / 1512 * 100vw));
    overflow-y: auto;
}


.titleAndInstructionContainer {
    color: #818181;
    font-size: calc(16 / 1512 * 100vw);
    margin-top: calc(34 / 1512 * 100vw);
    margin-bottom: calc(24 / 1512 * 100vw);
}

.title {
    font-weight: bold;
}

.table_container {
    width: 100%;
}

.totalCount {
    font-size: calc(14 / 1512 * 100vw);
    margin: calc(10 / 1512 * 100vw) 0;
    font-weight: bold;
}

.editLabel {
    font-size: calc(14 / 1512 * 100vw);
    margin-top: calc(20 / 1512 * 100vw);
    font-weight: bold;
    color: #818181;
}


.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0;
    font-size: calc(12 / 1512 * 100vw);
}

.table th,
.table td {
    border: calc(1 / 1512 * 100vw) solid #dce6f5;
    padding: calc(8 / 1512 * 100vw);
    text-align: left;

}

.table th {
    background-color: #001a2ac8;
    font-weight: bold;
    color: #fff;
}

.table tr:hover {
    background-color: #dce6f542;
}

.scrollableContainer {
    width: 100%;
}



.table tr:nth-child(even) {
    background-color: rgb(237, 237, 237);
}



.page_icons_grouped {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(10 / 1512 * 100vw);
    margin-top: calc(7 / 1512 * 100vw);
}

.angle_icon_container,
.angle_icon_container_inactive {
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    background: #F24401;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.5s all;
}

.angle_icon_container_inactive {
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
}




.tooltip_container {
    position: relative;
    cursor: pointer;
}

.tooltip {
    position: absolute;
    opacity: 1;
    padding: calc(5 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    background: #5c5c5c;
    color: #fff;
    transition: 0.5s all;
    transform: scale(0);
    transform-origin: left;
    z-index: 100;
}

.tooltip_container:hover .tooltip {
    opacity: 1;
    transform: scale(1);
}

.item_text {
    width: calc(100 / 1512 * 100vw);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-size: calc(14 / 1512 * 100vw);
}

.paginationInput {
    padding: calc(5 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    width: calc(50 / 1512 * 100vw);
    height: fit-content;
    border: calc(1.5 / 1512 * 100vw) solid #D9D9D9;
    background: none;
}

.pageContainer {
    display: flex;
    align-items: center;
    gap: calc(5 / 1512 * 100vw);
}

.loader_con {
    width: 100%;
    display: flex;
    justify-content: center;
    height: calc(100vh - calc(565 / 1512 * 100vw));
    align-items: center;
}


.image {
    width: 100%;
    height: calc(250 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    background: #F2F2F2;
    object-fit: cover;
}

.modalContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - calc(50 / 1512 * 100vw));
    gap: calc(14 / 1512 * 100vw);
    overflow: auto;
    position: relative;
}

.modalTitleAndDateContainer,
.modalStoreAddressContainer,
.modalTitleAndDescriptionContainer,
.smallContainer {
    display: flex;
    padding: calc(14 / 1512 * 100vw) calc(10 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    background-color: #001a2a;
    flex-direction: column;
    color: #fff;
}

.modalTitle {
    font-weight: bold;
    font-size: calc(16 / 1512 * 100vw);
}

.modalDate {
    font-size: calc(14 / 1512 * 100vw);
}

.modalStoreAddress {
    font-size: calc(14 / 1512 * 100vw);
    width: 90%;
    font-weight: bold;
}

.modalStoreAddressContainer,
.modalTitleAndDescriptionContainer,
.smallContainer {
    flex-direction: row;
    background: #f2f2f2;
    font-weight: bold;
    color: #001a2a;
    align-items: center;
}


.modalIcon {
    width: calc(20 / 1512 * 100vw);
    height: calc(20 / 1512 * 100vw);
}

.modalTitleAndDescriptionContainer {
    height: calc(180 / 1512 * 100vw);
    overflow-y: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.smallContainer {
    width: 49%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.messageTitle {
    font-weight: bold;
    font-size: calc(16 / 1512 * 100vw);
}

.messageDescription {
    font-size: calc(14 / 1512 * 100vw);
    font-weight: normal;
}

.smallGrouped {
    display: flex;
    justify-content: space-between;
}



.queryForm {
    display: flex;
    gap: calc(10 / 1512 * 100vw);
    flex-direction: row;
    width: calc(950 / 1512 * 100vw);
    overflow-x: auto;
    padding: 0 calc(10 / 1512 * 100vw);
}



.angle_icon_container:hover,
.clearFilter:hover,
.createButton:hover {
    opacity: 0.8;
}

.topContainer {
    width: 100%;
    display: flex;
    gap: calc(10 / 1512 * 100vw);
    margin: calc(20 / 1512 * 100vw) 0;
    justify-content: space-between;
    flex-wrap: wrap;
}

.input {
    outline: none;
    border: none;
    background: none;
    width: 100%;
    height: 100%;
}

.inputContainer,
.smallInputContainer,
.dateContainer,
.selectContainer {
    width: calc(470 / 1512 * 100vw);
    height: calc(48 / 1512 * 100vw);
    display: flex;
    gap: calc(10 / 1512 * 100vw);
    padding: calc(14 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    border: solid calc(1.5 / 1512 * 100vw) #002a3f4d;
    align-items: center;
}



.inputContainer {
    width: 40%;
}

.smallInputContainer {
    width: 50%;
}

.selectContainer {
    width: calc(150 / 1512 * 100vw);
}

.dateContainer {
    width: calc(130 / 1512 * 100vw);
}

.searchIcon {
    width: calc(20 / 1512 * 100vw);
    height: calc(20 / 1512 * 100vw);
}

.input,
.input::placeholder {
    font-size: calc(14 / 1512 * 100vw);
    color: #818181;
}

.dateGrouped {
    display: flex;
    gap: calc(5 / 1512 * 100vw);
    align-items: center;
    color: #909090;
    font-weight: bold;
}

.dataGenuinenessNameContainer {
    position: relative;
}

.dataGenuinenessNumberContainer {
    position: absolute;
    background: red;
    width: calc(30 / 1512 * 100vw);
    height: calc(30 / 1512 * 100vw);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    right: calc(10 / 1512 * 100vw);
    top: calc(-20 / 1512 * 100vw);
    color: #fff;
    font-size: calc(14 / 1512 * 100vw);
    font-weight: bold;
    box-shadow: 0px 11px 24px -8px rgba(0, 0, 0, 0.6);
}

.createButton {
    height: calc(48 / 1512 * 100vw);
    padding: calc(10 / 1512 * 100vw);
    background-color: #001a2ac8;
    color: #fff;
    font-size: calc(16 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    transition: 0.5s all;
    border: calc(2 / 1512 * 100vw) solid #001a2ac8;
}

.pendingButton,
.genuineBtn {
    width: calc(200 / 1512 * 100vw);
    padding: 0 calc(10 / 1512 * 100vw);
    display: flex;
    align-items: center;
    gap: calc(2 / 1512 * 100vw);
    background: none;
    color: #001a2a;
    border-color: red;
}

.pendingButton {
    border-color: green;
}

.manageUsersButton {
    background: none;
    color: #001a2ac8;
    font-size: calc(14 / 1512 * 100vw);
}

.divider {
    width: calc(1 / 1512 * 100vw);
    height: calc(40 / 1512 * 100vw);
    background: #001a2ac8;
    margin: 0 calc(10 / 1512 * 100vw);
}

.select-qr {
    width: calc(200 / 1512 * 100vw);
    background: none;
    color: #001a2ac8;
}

.downloadAll {
    background: rgb(0, 96, 10);
    color: #fff;
    width: calc(250 / 1512 * 100vw);
    border: calc(2 / 1512 * 100vw) solid rgb(0, 96, 10);

}


.secondContainer {
    width: 100%;
    display: flex;
    gap: calc(10 / 1512 * 100vw);
    margin-bottom: calc(7 / 1512 * 100vw);
}




.clearFilter {
    width: 174px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 14px;
    border-radius: 10px;
    background-color: #ffa347;
    color: #fff;
    transition: 0.5s all;
    cursor: pointer;
}

.mapModal {
    height: 100%;
    background: red;
}

.showMapParent {
    position: absolute;
    width: 100vw;
    height: 100%;
    background: rgba(0, 0, 0, 0.587);
    z-index: 1000;
    padding: calc(20 / 1512 * 100vw);
    padding-left: calc(90 / 1512 * 100vw);
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
}

.clickToOpenMap {
    cursor: pointer;
}

.showMapSecondParent {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: calc(10 / 1512 * 100vw);
}

.closeMap {
    background: #ffa347;
    padding: calc(10 / 1512 * 100vw) calc(20 / 1512 * 100vw);
    font-size: calc(14 / 1512 * 100vw);
    color: #fff;
    font-weight: bold;
    border-radius: calc(10 / 1512 * 100vw);
    cursor: pointer;
    transition: 0.5s all;
}

.closeMap:hover {
    opacity: 0.5;
}

.createAssetContainer {
    width: 100%;
}

.createInput {
    background: #f8f8f8;
    width: 100%;
    padding: calc(15 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
}


.createAssetModalTitle {
    font-size: calc(16 / 1512 * 100vw);
}

.createAssetSubContainer {
    display: flex;
    flex-direction: column;
    gap: calc(10 / 1512 * 100vw);
    margin-top: calc(20 / 1512 * 100vw);
}

.createInput::placeholder,
.createInput {
    font-size: calc(14 / 1512 * 100vw);
}

.inputFlex {
    display: flex;
    gap: calc(10 / 1512 * 100vw);
}

.bldNoInput {
    width: 20%;
}

.stateInput {
    width: city;
}

.createAssetSelect {
    padding: calc(10 / 1280 * 100vw);
}

.createInput option.placeholder {
    color: #888;
    /* Customize this color for the placeholder */
}







.img {
    width: 100%;
    height: calc(167 / 1280 * 100vw);
    object-fit: cover;
    border-radius: calc(10 / 1280 * 100vw);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.18);
}

.brandDesc {
    font-size: calc(14 / 1280 * 100vw);
    color: #43484f;
    width: calc(200 / 1512 * 100vw);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.event_info_container {
    display: flex;
    gap: calc(18 / 1280 * 100vw);
    flex-direction: column;
}

.event_info_sub_container {
    display: flex;
    align-items: center;
    color: #b5b6b9;
    gap: calc(5 / 1280 * 100vw);
}

.event_info_icon {
    width: calc(14 / 1280 * 100vw);
    height: calc(14 / 1280 * 100vw);
}

.brandTitle {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: calc(14 / 1280 * 100vw);
    text-transform: capitalize;
}

.get_ticket_button {
    width: 100%;
    padding: 0 calc(12 / 1280 * 100vw);
    font-size: calc(14 / 1280 * 100vw);
    text-align: center;
    font-weight: bold;
    color: #fff;
    border-radius: calc(10 / 1280 * 100vw);
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
    background-color: #001a2ac8;
    cursor: pointer;
    transition: 0.5s all;
    height: calc(40 / 1280 * 100vw);
    display: flex;
    align-items: center;
    justify-content: center;
}

.get_ticket_button:hover {
    background-color: hsla(207, 90%, 54%, 0.6);
}


.cta_group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: calc(10 / 1280 * 100vw);
}



.placeholderCard {
    width: calc(200 / 1280 * 100vw);
    height: fit-content;
    padding: calc(18 / 1280 * 100vw);
    gap: calc(10 / 1280 * 100vw);
    border-radius: calc(10 / 1280 * 100vw);
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #fff;
    display: flex;
    flex-direction: column;

}


.img {
    width: 100%;
    height: calc(167 / 1280 * 100vw);
    border-radius: calc(10 / 1280 * 100vw);
}

.text_placeholder,
.text_2_placeholder,
.btn_placeholder {
    height: calc(20 / 1280 * 100vw);
    border-radius: calc(20 / 1280 * 100vw);
    width: 100%;
}

.text_2_placeholder {
    height: calc(120 / 1280 * 100vw);
}

.btn_placeholder {
    height: calc(52 / 1280 * 100vw);
}


.noImage {
    width: calc(100 / 1280 * 100vw);
    height: calc(100 / 1280 * 100vw);
    margin-bottom: calc(20 / 1280 * 100vw);
    object-fit: cover;
}

.no_image_container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: calc(100 / 1280 * 100vw);
}

.no_item_to_display_text {
    width: 40%;
    text-align: center;
    font-size: calc(14 / 1280 * 100vw);
    /* font-weight: bold; */
    color: #001a2ac8;
}

.card {
    background: #fff;
    padding: calc(20 / 1280 * 100vw);
    border-radius: calc(10 / 1280 * 100vw);
    width: 32%;
    border: calc(2 / 1280 * 100vw) solid #fff;
}

.allSmallCards {
    display: flex;
    flex-wrap: wrap;
    gap: calc(10 / 1280 * 100vw);
    width: 100%;
    justify-content: space-between;
    margin: calc(20 / 1280 * 100vw) 0;
    font-weight: bold;
}

.title {
    font-size: calc(14 / 1280 * 100vw);
}

.value {
    font-size: calc(18 / 1280 * 100vw);
    font-weight: bold;
}

.ticketsContainer {
    background: #fff;
    border: calc(2 / 1512 * 100vw) solid #001a2a0e;
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    width: calc(260 / 1512 * 100vw);
}

.qrContainer {
    background-image: url('/src/assets/qr.png');
    width: calc(200 / 1280 * 100vw);
    height: calc(200 / 1280 * 100vw);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: calc(30 / 1280 * 100vw);
    position: relative;
}

.imageContainer {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #fff;
    border-radius: calc(3 / 1512 * 100vw);
    padding: calc(5 / 1512 * 100vw);
    border: calc(5 / 1512 * 100vw) solid #fff;
    display: flex;
    flex-direction: column;
    gap: calc(10 / 1512 * 100vw);
}

.allCards {
    display: flex;
    gap: calc(10 / 1512 * 100vw);
    flex-wrap: wrap;
    /* justify-content: space-between; */
}

.downloadButton {
    background: #001a2ac8;
    color: #fff;
    width: 100%;
    padding: calc(10 / 1280 * 100vw);
    outline: none;
    border: none;
    border-radius: calc(5 / 1280 * 100vw);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.18);
    font-size: calc(12 / 1280 * 100vw);
    cursor: pointer;
    transition: 0.5s all;
}

.downloadButton:hover {
    opacity: 0.5;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
}

.modalContent {
    position: relative;
    background-color: #fff;
    padding: calc(20 / 1280 * 100vw);
    border-radius: calc(8 / 1280 * 100vw);
    text-align: center;
}

.qrCode {
    width: calc(200 / 1280 * 100vw);
    height: calc(200 / 1280 * 100vw);
}


.subTicketInfoContainer {
    padding: 0 calc(10 / 1280 * 100vw) calc(10 / 1280 * 100vw) calc(10 / 1280 * 100vw);
    text-align: center;
}

.viewMapIconBackground {
    background: #fff;
    width: fit-content;
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    margin-left: auto;
    transition: 0.5s all;
    cursor: pointer;
    position: absolute;
    border: calc(2 / 1512 * 100vw) solid #0000003f;
    right: calc(10 / 1512 * 100vw);
    top: calc(10 / 1512 * 100vw);
    box-shadow: 0px 11px 24px -8px rgba(0, 0, 0, 0.6);

}

.history {
    top: calc(70 / 1512 * 100vw);
}

.download {
    top: calc(70 / 1512 * 100vw);
    background: rgb(0, 96, 10);
    border: calc(2 / 1512 * 100vw) solid #fff;
}





.viewMapIcon {
    width: calc(30 / 1512 * 100vw);
    height: calc(30 / 1512 * 100vw);
    color: #000;
}

.viewMapIconBackground:hover {
    transform: scale(0.9);
}

.outletsModal {
    background: #f8f8f8;
    padding: calc(10 / 1512 * 100vw);
    display: flex;
    flex-direction: column;
    gap: calc(10 / 1512 * 100vw);
}

.outletsNames {
    font-size: calc(14 / 1512 * 100vw);
    background: #fff;
    padding: calc(10 / 1512 * 100vw);
    cursor: pointer;
    transition: 0.5s all;
}

.outletsNames:hover {
    transform: scale(0.98);
}

.bannerImage {
    width: 100%;
    height: calc(250 / 1512 * 100vw);
    object-fit: cover;
    object-position: center;
    cursor: pointer;
}

.createBtn {
    font-size: calc(16 / 1512 * 100vw);
    background: #001a2ac8;
    color: #fff;
    width: 100%;
    padding: calc(10 / 1512 * 100vw);
    margin-bottom: calc(30 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    display: flex;
    justify-content: center;
    align-content: center;
}



.nameAndBackContainer {
    height: calc(40 / 1512 * 100vw);
    background: #fff;
    margin-bottom: calc(10 / 1512 * 100vw);
    display: flex;
    align-items: center;
    padding: 0 calc(10 / 1512 * 100vw);
    font-weight: bold;
    gap: calc(5 / 1512 * 100vw);
}



.screenContainer {
    display: flex;
    justify-content: space-between;
    gap: calc(10 / 1512 * 100vw);
}

.itemsContainer {
    display: flex;
    flex-direction: column;
    gap: calc(10 / 1512 * 100vw);
    width: 20%;
}

.screen {
    width: 100%;
    background: #fff;
    padding: calc(10 / 1512 * 100vw) calc(20 / 1512 * 100vw);
    overflow: auto;
}

.downloadSection {
    display: flex;
    gap: calc(10 / 1512 * 100vw);
    margin-bottom: calc(30 / 1512 * 100vw);
    align-items: center;
}


.showing {
    margin-right: auto;
    font-size: calc(18 / 1512 * 100vw);
    width: calc(150 / 1512 * 100vw);
    font-weight: bold;

}

.listParentHoldingCheckbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: calc(10 / 1512 * 100vw);
}

.AssetsSelectionCheckbox {
    accent-color: #0000003f;
    width: calc(15 / 1512 * 100vw);
    height: calc(15 / 1512 * 100vw);

}


.cantFindStatement {
    font-size: calc(16 / 1512 * 100vw);
    margin-bottom: calc(40 / 1512 * 100vw);
    cursor: pointer;
    transition: 0.5s all;
}

.cantFindStatement:hover {
    transform: scale(0.95);
}


.createContainer {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.553);
    z-index: 2000;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.createTitle {
    font-weight: bold;
    font-size: calc(20 / 1512 * 100vw);
    margin-bottom: calc(10 / 1512 * 100vw);
    text-align: center;
}

.createModalWhite {
    width: calc(600 / 1512 * 100vw);
    height: 80%;
    overflow: auto;
    border-radius: calc(10 / 1512 * 100vw);
    padding: calc(20 / 1512 * 100vw);
    background: #fff;
    display: flex;
    flex-direction: column;
}

.closeContainer {
    display: flex;
}

.closeBtn {
    width: calc(30 / 1512 * 100vw);
    height: calc(30 / 1512 * 100vw);
    color: red;
    margin-left: auto;
}

.selectedUserGroupedContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: calc(10 / 1512 * 100vw);
}

.selectedUserIndividualContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: calc(14 / 1512 * 100vw);
    width: fit-content;
    gap: calc(20 / 1512 * 100vw);
    background: #eeeeee;
    padding: calc(5 / 1512 * 100vw) calc(10 / 1512 * 100vw);
}

.removeUserFromListIcon {
    color: red;
    cursor: pointer;
    transition: 0.5s;
}

.removeUserFromListIcon:hover {
    transform: scale(1.3);
}


.topNames {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(16 / 1512 * 100vw);
    gap: calc(30 / 1512 * 100vw);
    margin-bottom: calc(30 / 1512 * 100vw);
    font-weight: bold;

}

.topNamesText {
    cursor: pointer;
    transition: 0.5s all;
}

.topNamesText:hover {
    opacity: 0.5;
}

.activeTopNamesText {
    border-bottom: calc(5 / 1512 * 100vw) solid #ffa347;

}


.smallEmail {
    font-size: calc(12 / 1512 * 100vw);
    font-weight: normal;

}

.allUsers {
    display: flex;
    flex-direction: column;
    gap: calc(10 / 1512 * 100vw);
    margin-top: calc(10 / 1512 * 100vw)
}

.addedUsers {
    background: #eeeeee;
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    font-size: calc(14 / 1512 * 100vw);
    font-weight: bold;
    display: flex;
    align-items: center;
}

.loaderCon {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
}

.addedGroups {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.dropdownIcon {
    width: calc(20 / 1512 * 100vw);
    height: calc(20 / 1512 * 100vw);
    color: #484848;
    transition: 0.5s all;
    cursor: pointer;
}


.toggleOpen {
    transform: rotate(180deg);
}

.assetsList {
    padding-left: calc(10 / 1512 * 100vw);
    padding-right: calc(10 / 1512 * 100vw);
    display: flex;
    margin-top: calc(10 / 1512 * 100vw);
    gap: calc(10 / 1512 * 100vw);
}

.assetsListSub {
    display: flex;
    gap: calc(10 / 1512 * 100vw);
    width: 90%;
    flex-wrap: wrap;
}

.returnRightIcon {
    width: calc(30 / 1512 * 100vw);
    height: calc(30 / 1512 * 100vw);
}

.assetItem {
    background: #43484f;
    color: #fff;
    padding: calc(5 / 1512 * 100vw) calc(10 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    font-size: calc(14 / 1512 * 100vw);
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    gap: calc(7 / 1512 * 100vw);

}

.addToListButton {
    background: #ffa347;
    padding: calc(5 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: calc(14 / 1512 * 100vw);
    color: #fff;
    width: 100%;
    font-weight: bold;
    cursor: pointer;
    transition: 0.5s all;
}

.addToListButton:hover {
    opacity: 0.5;
}

.flexTitleAndInstructionContainer {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.date {
    background: #eeeeee;
    padding: calc(10 / 1512 * 100vw) calc(20 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
}


.topButtonContainer {
    display: flex;
    gap: calc(10 / 1512 * 100vw);
    text-transform: capitalize;
    justify-content: flex-end;
}

.resolveButton,
.deleteButton {
    background: #2ea428;
    padding: calc(5 / 1512 * 100vw) calc(10 / 1512 * 100vw);
    color: #fff;
    border-radius: calc(5 / 1512 * 100vw);
    font-size: calc(14 / 1512 * 100vw);
    cursor: pointer;
    transition: 0.5s all;
    transform-origin: right;
}

.deleteButton {
    background: #9a0000;
}


.resolveButton:hover,
.deleteButton:hover {
    transform: scale(1.1);
}

.deleteModal {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #00000062;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(10 / 1512 * 100vw);
    z-index: 2;
}

.deleteSubModal {
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: calc(20 / 1512 * 100vw);
    align-items: center;
    justify-content: center;
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
}

.deleteQuestion {
    font-size: calc(18 / 1512 * 100vw);
    font-weight: bold;
    width: 60%;
    text-align: center;
}

.promptBtnGrouped {
    display: flex;
    gap: calc(10 / 1512 * 100vw);
}

.promptBtn {
    padding: calc(10 / 1512 * 100vw) calc(20 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    background: rgb(217, 217, 217);
    transition: 0.5s all;
}

.promptBtn:hover {
    opacity: 0.5;
}

.deleteUserCon {
    margin-left: auto;
}

.deleteIcon {
    display: flex;
    align-items: center;
    width: calc(20 / 1512 * 100vw);
    height: calc(20 / 1512 * 100vw);
    cursor: pointer;
    transition: 0.5s all;
}

.deleteIcon:hover {
    transform: scale(1.3);
}




.closeIcon {
    height: calc(15 / 1512 * 100vw);
    color: red;
    cursor: pointer;
    transition: 0.5s all;
}




.leftSideWithArrow {
    display: flex;
    align-items: center;
    gap: calc(20 / 1512 * 100vw);
}

.deleteGroupText {
    font-size: calc(14 / 1512 * 100vw);
    padding: calc(2 / 1512 * 100vw) calc(7 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    font-weight: bold;
    background: red;
    color: #fff;
    cursor: pointer;
    transition: 0.5s all;
}

.closeIcon:hover {
    transform: scale(1.5);
}

.deleteGroupText:hover {
    transform: scale(1.2);
}