.container {
    width: 100%;
    display: flex;
    gap: calc(2 / 1512 * 100vw);
    overflow-y: hidden;
    height: 100vh;
}

.componentContainer {
    padding: calc(10 / 1512 * 100vw);
    height: calc(100vh - calc(70 / 1512 * 100vw));
    overflow-y: auto;
}

.progressContainer {
    padding: calc(5 / 1512 * 100vw) calc(10 / 1512 * 100vw);
    font-size: calc(14 / 1512 * 100vw);
}

.parent{
    width: 100%;
    position: absolute;
    top: 0
}
