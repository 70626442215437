

.page_icons_grouped {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(10 / 1512 * 100vw);
    margin-top: calc(7 / 1512 * 100vw);
}

.angle_icon_container,
.angle_icon_container_inactive {
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    background: #F24401;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.5s all;
}

.angle_icon_container_inactive {
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
}

.paginationInput {
    padding: calc(5 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    width: calc(50 / 1512 * 100vw);
    height: fit-content;
    border: calc(1.5 / 1512 * 100vw) solid #D9D9D9;
    background: none;
}

.pageContainer {
    display: flex;
    align-items: center;
    gap: calc(5 / 1512 * 100vw);
}