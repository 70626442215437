.createButton {
    background: #ff6f61;
    background: #001a2a;
    padding: calc(10 / 1512 * 100vw) calc(20 / 1512 * 100vw);
    color: #fff;
    border-radius: calc(5 / 1512 * 100vw);
    font-size: calc(16 / 1512 * 100vw);
    margin-right: calc(5 / 1512 * 100vw);
    box-shadow: 0px 11px 24px -8px rgba(0, 0, 0, 0.6);
}


.createButton:hover {
    opacity: 0.8;
}