.container {
    display: flex;
    height: calc(50 / 1512 * 100vw);
    gap: calc(28 / 1512 * 100vw);
    justify-content: center;
    align-items: center;
    color: #909090;
    font-size: calc(16 / 1512 * 100vw);
    margin-bottom: calc(23 / 1512 * 100vw);
}

.indicator {
    width: calc(18 / 1512 * 100vw);
    height: calc(2 / 1512 * 100vw);
    background: #ffa347;
}

.itemsContainer {
    cursor: pointer;
    transition: 0.5s all;
}

.itemsContainer:hover {
    opacity: 0.5;
}