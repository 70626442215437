.nameAndBackContainer {
    height: calc(40 / 1512 * 100vw);
    background: #fff;
    margin-bottom: calc(10 / 1512 * 100vw);
    display: flex;
    align-items: center;
    padding: 0 calc(10 / 1512 * 100vw);
    font-weight: bold;
    gap: calc(5 / 1512 * 100vw);
}

.screenContainer {
    display: flex;
    justify-content: space-between;
    gap: calc(10 / 1512 * 100vw);
}

.itemsContainer{
    display: flex;
    flex-direction: column;
    gap: calc(10 / 1512 * 100vw);
    width: 20%;
}

.screen {
    width: 80%;
    background: #fff;
    padding: calc(10 / 1512 * 100vw) calc(20 / 1512 * 100vw);
}


.container {
    height: calc(100vh - calc(90 / 1512 * 100vw));
    overflow: hidden;
}