.container,
.chunkContainer {
    padding: calc(20 / 1512 * 100vw);
    height: calc(100vh - calc(300 / 1512 * 100vw));
    background: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: calc(20 / 1512 * 100vw);

}



.title {
    font-size: calc(16 / 1512 * 100vw);
    font-weight: bold;
    padding: calc(5 / 1512 * 100vw);
}

.navItemsGrouped,
.chunkNavItemsGrouped {
    display: flex;
    flex-direction: column;
    gap: calc(10 / 1512 * 100vw);
    height: calc(100vh - calc(70 / 1512 * 100vw));
    overflow-y: auto;
}


.chunkContainer,
.chunkNavItemsGrouped {
    height: fit-content;

}

.navItem {
    font-size: calc(14 / 1512 * 100vw);
    padding: calc(5 / 1512 * 100vw) calc(10 / 1512 * 100vw);
    background-color: rgba(250, 250, 250, 0.783);
    transition: 0.5s al;
    cursor: pointer;
}

.navItem:hover {
    opacity: 0.5;
}