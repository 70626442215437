.container {
    background: #001a2a;
    /* width: 7%; */
    height: 100vh;
    padding: calc(10 / 1512 * 100vw) calc(20 / 1512 * 100vw);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 6px 10px 78px -10px rgba(0, 0, 0, 0.9);
    z-index: 1;
}

.icon {
    color: #fff;
    width: calc(25 / 1512 * 100vw);
    height: calc(25 / 1512 * 100vw);
    color: #7a7c7f;
}

.iconsGrouped {
    display: flex;
    gap: calc(25 / 1512 * 100vw);
    flex-direction: column;
}

.logo {
    width: calc(40 / 1512 * 100vw);
    margin: calc(20 / 1512 * 100vw) 0 calc(40 / 1512 * 100vw) 0;
}

.navContainer {
    display: flex;
    position: relative;
    color: #fff;
    cursor: pointer;
    transition: 0.5s all;
}

.name,
.profileModal {
    color: #001a2a;
    background: #ffffff;
    position: absolute;
    left: calc(30 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    box-shadow: 0px 11px 24px -8px rgba(0, 0, 0, 0.6);
    opacity: 0;
    z-index: -3;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
}

.navContainer:hover .name {
    z-index: -31;
    opacity: 1;
    overflow: initial;
    padding: calc(10 / 1512 * 100vw);
    width: fit-content;
    height: fit-content;
}


.avatarContainer:hover .profileModal {
    z-index: 1;
    opacity: 1;
    padding: calc(10 / 1512 * 100vw);
    bottom: calc(30 / 1512 * 100vw);
    width: calc(200 / 1512 * 100vw);
    height: fit-content;

}

.profileModal {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.avatarContainer {
    margin-top: auto;
    display: flex;
    cursor: pointer;
    transition: 0.5s all;
    position: relative;
}

.profileNav {
    font-size: calc(16 / 1512 * 100vw);
    display: flex;
    gap: calc(5 / 1512 * 100vw);
    align-items: center;
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    cursor: pointer;
    transition: 0.5s all;
}

.profileNav:hover {
    background: #001a2a;
    color: #fff;
}