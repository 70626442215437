.container {
    width: 100%;
    overflow: hidden;
}

.screenContainer {
    width: 100%;
    height: calc(100vh - calc(210 / 1512 * 100vw));
    overflow-y: auto;
    overflow-x: hidden;
}


.titleAndInstructionContainer {
    color: #818181;
    font-size: calc(16 / 1512 * 100vw);
    margin-top: calc(34 / 1512 * 100vw);
    margin-bottom: calc(24 / 1512 * 100vw);
}

.title {
    font-weight: bold;
}

.table_container {
    width: 100%;
}



.showing {
    margin: calc(20 / 1512 * 100vw) 0;
    font-size: calc(16 / 1512 * 100vw);
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0;
    font-size: calc(12 / 1512 * 100vw);
}

.table th,
.table td {
    border: calc(1 / 1512 * 100vw) solid #dce6f5;
    padding: calc(8 / 1512 * 100vw);
    text-align: left;

}

.table th {
    background-color: #001a2ac8;
    font-weight: bold;
    color: #fff;
}

.table tr:hover {
    background-color: #dce6f542;
}

.scrollableContainer {
    width: 100%;
}



.table tr:nth-child(even) {
    background-color: rgb(237, 237, 237);
}



.page_icons_grouped {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(10 / 1512 * 100vw);
    margin-top: calc(7 / 1512 * 100vw);
}

.angle_icon_container,
.angle_icon_container_inactive {
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    background: #F24401;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.5s all;
}

.angle_icon_container_inactive {
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
}




.tooltip_container {
    position: relative;
    cursor: pointer;
}

.tooltip {
    position: absolute;
    opacity: 1;
    padding: calc(5 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    background: #5c5c5c;
    color: #fff;
    transition: 0.5s all;
    transform: scale(0);
    transform-origin: left;
    z-index: 100;
}

.tooltip_container:hover .tooltip {
    opacity: 1;
    transform: scale(1);
}

.item_text {
    width: calc(100 / 1512 * 100vw);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-size: calc(14 / 1512 * 100vw);
}

.paginationInput {
    padding: calc(5 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    width: calc(50 / 1512 * 100vw);
    height: fit-content;
    border: calc(1.5 / 1512 * 100vw) solid #D9D9D9;
    background: none;
}

.pageContainer {
    display: flex;
    align-items: center;
    gap: calc(5 / 1512 * 100vw);
}

.loader_con {
    width: 100%;
    display: flex;
    justify-content: center;
    height: calc(100vh - calc(565 / 1512 * 100vw));
    align-items: center;
}


.image {
    width: 100%;
    height: calc(250 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    background: #F2F2F2;
    object-fit: cover;
}

.modalContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - calc(50 / 1512 * 100vw));
    gap: calc(14 / 1512 * 100vw);
    overflow: auto;
}

.modalTitleAndDateContainer,
.modalStoreAddressContainer,
.modalTitleAndDescriptionContainer,
.smallContainer {
    display: flex;
    padding: calc(14 / 1512 * 100vw) calc(10 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    background-color: #001a2a;
    flex-direction: column;
    color: #fff;
}

.modalTitle {
    font-weight: bold;
    font-size: calc(16 / 1512 * 100vw);
}

.modalDate {
    font-size: calc(14 / 1512 * 100vw);
}

.modalStoreAddress {
    font-size: calc(14 / 1512 * 100vw);
    width: 90%;
    font-weight: bold;
}

.modalStoreAddressContainer,
.modalTitleAndDescriptionContainer,
.smallContainer {
    flex-direction: row;
    background: #f2f2f2;
    font-weight: bold;
    color: #001a2a;
    align-items: center;
}


.modalIcon {
    width: calc(20 / 1512 * 100vw);
    height: calc(20 / 1512 * 100vw);
}

.modalTitleAndDescriptionContainer {
    height: calc(180 / 1512 * 100vw);
    overflow-y: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.smallContainer {
    width: 49%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.messageTitle {
    font-weight: bold;
    font-size: calc(16 / 1512 * 100vw);
}

.messageDescription {
    font-size: calc(14 / 1512 * 100vw);
    font-weight: normal;
}

.smallGrouped {
    display: flex;
    justify-content: space-between;
}



.queryForm {
    display: flex;
    gap: calc(10 / 1512 * 100vw);
    flex-direction: row;
    width: calc(950 / 1512 * 100vw);
    overflow-x: auto;
    padding:0  calc(10 / 1512 * 100vw);
}



.angle_icon_container:hover,
.clearFilter:hover,
.exportButton:hover {
    opacity: 0.8;
}