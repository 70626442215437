.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.container {
    width: 100%;
    height: 100%;
}

.cardContainer {
    width: calc(170 / 1512 * 100vw);
    height: fit-content;
    gap: calc(9 / 1512 * 100vw);
    padding: calc(10 / 1512 * 100vw) calc(10 / 1512 * 100vw) calc(20 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    border: solid calc(1 / 1512 * 100vw) #f4f4f4;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cardImage {
    width: 100%;
    height: 100%;
    border-radius: calc(10 / 1512 * 100vw);
    height: calc(118 / 1512 * 100vw);
    background-color: #d9d9d9;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    background-image: url('/src/assets/placeholderImage.png');
}


.position {
    background: #E69500;
    font-size: calc(14 / 1512 * 100vw);
    height: fit-content;
    padding: calc(5 / 1512 * 100vw) calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    margin-left: auto;
    color: #fff;
    font-weight: bold;
    margin-top: auto;
}

.cardName,
.cardLinks {
    font-size: calc(14 / 1512 * 100vw);
    font-weight: bold;
    text-align: center;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-transform: capitalize;
    color: #001a2a;
}

.cardLinks {
    font-weight: normal;
    text-transform: lowercase;
}

.cardGrouped{
    display: flex;
    gap: calc(5 / 1512 * 100vw);
    flex-wrap: wrap;
    height: calc(100vh - calc(320 / 1512 * 100vw));
    overflow-y: auto;
    /* justify-content: space-between; */
}


.empty_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - calc(565 / 1512 * 100vw));
    width: 100%;
}

.empty_message{
    font-size: calc(18 / 1512 * 100vw);
    width: 100%;
    text-align: center;
    width: 40%;
    font-weight: 500;
    color: #001a2a;
    margin-top: calc(20 / 1512 * 100vw);
}

.empty_image{
    width: calc(200 / 1512 * 100vw);
}


.title {
    font-weight: bold;
    color: #818181;
    font-size: calc(16 / 1512 * 100vw);
    margin-top: calc(34 / 1512 * 100vw);
    margin-bottom: calc(24 / 1512 * 100vw);
}