.empty_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: calc(50 / 1512 * 100vw);
}

.empty_message {
    font-size: calc(18 / 1512 * 100vw);
    width: 100%;
    text-align: center;
    width: 40%;
    font-weight: 500;
    color: #001a2a;
}

.empty_image {
    width: calc(200 / 1512 * 100vw);
}