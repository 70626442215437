/* HTML: <div class="loader"></div> */
.loader {
  --c: no-repeat linear-gradient(#ffa347 0 0);
  background:
    var(--c), var(--c), var(--c),
    var(--c), var(--c), var(--c),
    var(--c), var(--c), var(--c);
  background-size: calc(16 / 1512 * 100vw) calc(16 / 1512 * 100vw);
  animation:
    l32-1 1s infinite,
    l32-2 1s infinite;
}

@keyframes l32-1 {

  0%,
  100% {
    width: calc(45 / 1512 * 100vw);
    height: calc(45 / 1512 * 100vw)
  }

  35%,
  65% {
    width: calc(65 / 1512 * 100vw);
    height: calc(65 / 1512 * 100vw)
  }
}

@keyframes l32-2 {

  0%,
  40% {
    background-position: 0 0, 0 50%, 0 100%, 50% 100%, 100% 100%, 100% 50%, 100% 0, 50% 0, 50% 50%
  }

  60%,
  100% {
    background-position: 0 50%, 0 100%, 50% 100%, 100% 100%, 100% 50%, 100% 0, 50% 0, 0 0, 50% 50%
  }
}