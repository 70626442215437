.container,
.container_inactive {
    transition: 0.5s all;
    transform: scale(1);
    transform-origin: center;
    display: flex;
    width: 100%;
    /* height: 100%; */
    position: fixed;
    left: 0;
    right: 0;
    z-index: 999;
    bottom: calc(140 / 1280 * 100vw);
}


.container_inactive {
    transform: scale(0);
    overflow: hidden;
    display: none;
}

.video {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.leave {
    position: absolute;
    background: #000;
    width: 100%;
    color: #fff;
    padding: calc(5 / 1280 * 100vw) calc(40 / 1280 * 100vw);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hang_up_div {
    display: flex;
    flex-direction: row;
    width: fit-content;
    color: red;
    align-items: center;
    gap: calc(10 / 1280 * 100vw);
    cursor: pointer;
}

.call_visibility_btn {
    position: fixed;
    background: green;
    z-index: 1000;
    color: #fff;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: calc(5 / 1280 * 100vw);
    top: 0;
    font-weight: bold;
    animation: loadingSkeleton 2s infinite;
}

@keyframes loadingSkeleton {
    0% {
        background-color: #3cb043;
        /* Lighter shade of green */
    }

    50% {
        background-color: green;
        /* Original green */
    }

    100% {
        background-color: #3cb043;
        /* Lighter shade of green */
    }
}

.minimize_icon {
    width: calc(20 / 1280 * 100vw);
    height: calc(20 / 1280 * 100vw);
    cursor: pointer;
    transition: 0.2s all;
}

.minimize_icon:hover{
    opacity: 0.5;
}

@media screen and (max-width: 999px) {

    .container,
    .container_inactive {
        background: none;
        transition: 0.5s all;
        transform: scale(1);
        transform-origin: center;
        display: flex;
        width: 100%;
        height: fit-content;
        margin-right: 0;
        position: fixed;
        bottom: calc(30 / 999 * 100vw);
        padding: calc(10 / 999 * 100vw);
        left: 0;
        overflow-y: auto;
    }


    .container_inactive {
        transform: scale(0);
        overflow: hidden;
        display: none;
    }

    .video {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


    .leave {
        position: absolute;
        background: #000;
        width: calc(100vw - calc(20 / 999 * 100vw));
        color: #fff;
        padding: calc(5 / 999 * 100vw) calc(10 / 999 * 100vw);
        display: flex;
        justify-content: space-between;
    }

    .hang_up_div {
        display: flex;
        flex-direction: row;
        width: fit-content;
        color: red;
        align-items: center;
        gap: calc(10 / 999 * 100vw);
        cursor: pointer;
    }

    .call_visibility_btn {
        position: fixed;
        background: green;
        z-index: 1000;
        color: #fff;
        width: 100%;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: calc(5 / 999 * 100vw);
        top: 0;
        font-weight: bold;
        animation: loadingSkeleton 2s infinite;
    }



    .minimize_icon {
        width: calc(20 / 999 * 100vw);
        height: calc(20 / 999 * 100vw);
    }

}

@media screen and (max-width: 540px) {

    .container,
    .container_inactive {
        background: none;
        transition: 0.5s all;
        transform: scale(1);
        transform-origin: center;
        display: flex;
        width: 100%;
        height: fit-content;
        margin-right: 0;
        position: fixed;
        bottom: calc(30 / 414 * 100vw);
        padding: calc(10 / 414 * 100vw);
        left: 0;
        overflow-y: auto;
    }


    .container_inactive {
        transform: scale(0);
        overflow: hidden;
        display: none;
    }

    .video {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


    .leave {
        position: absolute;
        background: #000;
        width: calc(100vw - calc(20 / 414 * 100vw));
        color: #fff;
        padding: calc(5 / 414 * 100vw) calc(10 / 414 * 100vw);
        display: flex;
        justify-content: space-between;
    }

    .hang_up_div {
        display: flex;
        flex-direction: row;
        width: fit-content;
        color: red;
        align-items: center;
        gap: calc(10 / 414 * 100vw);
        cursor: pointer;
    }

    .call_visibility_btn {
        position: fixed;
        background: green;
        z-index: 1000;
        color: #fff;
        width: 100%;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: calc(5 / 540 * 100vw);
        top: 0;
        font-weight: bold;
        animation: loadingSkeleton 2s infinite;
    }



    .minimize_icon {
        width: calc(20 / 540 * 100vw);
        height: calc(20 / 540 * 100vw);
    }

}