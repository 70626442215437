.screenContainer {
  width: 100%;
  height: calc(100vh - calc(160 / 1512 * 100vw));
  overflow-y: auto;
}

.loader_con {
  width: 100%;
  display: flex;
  justify-content: center;
  height: calc(100vh - calc(565 / 1512 * 100vw));
  align-items: center;
  flex-direction: column;
  gap: calc(10 / 1512 * 100vw);
}

.apology {
  width: 50%;
  font-size: calc(16 / 1512 * 100vw);
  text-align: center;
  color: #1d3557;
}

.cardContainer {
  font-size: calc(16 / 1512 * 100vw);
  background-color: #f5f6f7;
  color: #1d3557;
  width: 32%;
  padding: calc(20 / 1512 * 100vw);
  border-radius: calc(15 / 1512 * 100vw);
  text-align: left;
}

.cardGrouped {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: calc(20 / 1512 * 100vw);
}

.table_container {
  display: flex;
  flex-direction: column;
  gap: calc(20 / 1512 * 100vw);
}

.cardValue {
  font-size: calc(24 / 1512 * 100vw);
  font-weight: bold;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0;
  font-size: calc(12 / 1512 * 100vw);
  background: #f5f6f7;
  border-radius: calc(15 / 1512 * 100vw);
  overflow: hidden;
}

.table th,
.table td {
  border: calc(2 / 1512 * 100vw) solid #001a2a22;
  padding: calc(10 / 1512 * 100vw);
  text-align: left;
}

.table th {
  background-color: #001a2ac8;
  font-weight: bold;
  color: #fff;
}

.table tr:hover {
  background-color: #dce6f542;
}

.tooltip_container {
  position: relative;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  opacity: 1;
  padding: calc(5 / 1512 * 100vw);
  border-radius: calc(5 / 1512 * 100vw);
  background: #5c5c5c;
  color: #fff;
  transition: 0.5s all;
  transform: scale(0);
  transform-origin: left;
  z-index: 100;
}

.tooltip_container:hover .tooltip {
  opacity: 1;
  transform: scale(1);
}

.item_text {
  width: calc(300 / 1512 * 100vw);

  font-size: calc(14 / 1512 * 100vw);
}

.progressContainer {
  background: #fff;
  width: 100%;
  height: calc(10 / 1512 * 100vw);
}

.performanceValue {
  color: #fff;
  width: fit-content;
  font-weight: bold;
  padding: calc(10 / 1512 * 100vw) calc(20 / 1512 * 100vw);
  border-radius: calc(10 / 1512 * 100vw);
}

.performanceParentContainer {
  width: 48%;
  background-color: #f5f6f7;
  gap: calc(20 / 1512 * 100vw);
  display: flex;
  flex-direction: column;
  padding: calc(20 / 1512 * 100vw);
  border-radius: calc(10 / 1512 * 100vw);
}

.performanceContainer {
  background: #001a2a22;
  padding: calc(10 / 1512 * 100vw);
  border-radius: calc(10 / 1512 * 100vw);
  font-size: calc(16 / 1512 * 100vw);
  gap: calc(10 / 1512 * 100vw);
  display: flex;
  flex-direction: column;
}

.progress {
  color: #1d3557;
}

.performanceContainerTitle {
  font-weight: bold;
}

.groupedPerformance {
  display: flex;
  justify-content: space-between;
}
