.ex {
  width: 240px;

  .list-item {
    margin: 3px 2rem;
  }
}

.col {
  width: 80px;

}

.sidebar {
  background-color: #FFFFFF;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0px 4px 16px 0px #00000014;
  z-index: 100;
  position: fixed;
  padding: 0 0 1rem 0;
  transition: 250ms ease;
  border-right: 1px solid #E4E3E7;

  .logo {
    padding: 2rem 4rem;
  }

  .nav {
    justify-content: left;
    width: 100%;
    // margin-left: 17px;
    margin-top: .5rem;
    background-color: inherit;
    box-sizing: border-box;

    .activex {
      // background-color: #FFEAEA !important;
      color: #F97066 !important;
      font-weight: 600 !important;
      transform: scale(1.02);

      .chev {
        color: #003C4E !important;
      }

      .img {
        color: #FDA29B !important;
      }

    }

    .activexx {
      background-color: #244CC6 !important;
      color: #FFF !important;
      border-left: 6px solid #244CC6;
      font-weight: 500 !important;
      transform: scale(1.02);

      .img {
        color: #344054 !important;
      }



    }

    .activexxx {
      background-color: #EDF1FF !important;
      color: #244CC6 !important;
      font-weight: 500 !important;
      transform: scale(1.02);

      .img {
        color: #FDA29B !important;
      }



    }

    .list-item {
      color: #344054;
      text-decoration: none;
      display: block;
      border-radius: 12px;
      transition: 250ms ease;
      padding: 17px 13px;
      font-size: 14px;
      // margin: 3px 2rem;
      font-weight: 400;
      display: flex;
      align-items: center;
      position: relative;


    }

    .list-itemx {
      color: #36373A;
      text-decoration: none;
      display: block;
      border-radius: 0px;
      transition: 250ms ease;
      //   margin: 13px auto;
      padding: 17px 13px;
      font-size: .87rem;
      font-weight: 400;
      display: flex;
      align-items: center;
      position: relative;


    }

    img {
      margin-right: .6rem;
      font-size: 1.3em;
    }

    .img {
      margin-right: .6rem;
      font-size: 1.3em;
      color: #344054;
    }
  }
  
}