.container {
    background: #fff;
    height: 100%;
    border-radius: calc(20 / 1512 * 100vw);
    padding: calc(36 / 1512 * 100vw) calc(50 / 1512 * 100vw);
    display: flex;
    flex-direction: column;
    gap: calc(27 / 1512 * 100vw);
    width: 100%;
}

.table_container {
    width: 100%;
}

.page_title_publish_grouped {
    display: flex;
    align-items: flex-start;
}

.intro_text {
    font-size: calc(30 / 1512 * 100vw);
    font-weight: bold;
    color: #000;
}

.publish_btn {
    padding: calc(10 / 1512 * 100vw) calc(20 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    color: #fff;
    background: #000;
    margin-left: auto;
    cursor: pointer;
    transition: 0.5s all;
}

.publish_btn:hover {
    opacity: 0.6;
}

.intro_sub_text {
    font-size: calc(18 / 1512 * 100vw);
    color: #939292;
}

.empty_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - calc(420 / 1512 * 100vw));
}

.empty_image {
    width: calc(200 / 1512 * 100vw);
}

.scrollable {
    height: calc(100vh - calc(300 / 1512 * 100vw));
    overflow: auto;
    width: 100%;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
}

.scrollable::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, and Opera */
}

.empty_message {
    font-size: calc(16 / 1512 * 100vw);
    transition: 0.5s all;
    cursor: pointer;
    width: 30%;
    text-align: center;
}

.search_container {
    width: 100%;
    height: calc(45 / 1512 * 100vw);
    display: flex;
    padding: calc(10 / 1512 * 100vw) calc(15 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    gap: calc(5 / 1512 * 100vw);
    border: solid calc(2 / 1512 * 100vw) #FFEAEA;
    align-items: center;
}

.search_icon {
    width: calc(20 / 1512 * 100vw);
    height: calc(20 / 1512 * 100vw);
}

.search_input {
    width: 100%;
    height: 100%;
    background: none;
    outline: none;
    border: none;
}

.top_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: calc(10 / 1512 * 100vw);
}

.filter_btn {
    height: calc(45 / 1512 * 100vw);
    font-size: calc(16 / 1512 * 100vw);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: calc(10 / 1512 * 100vw);
    border: solid calc(2 / 1512 * 100vw) #FFEAEA;
    /* border: solid calc(2 / 1512 * 100vw) #000; */
    cursor: pointer;
    transition: 0.5s all;
    padding: calc(10 / 1512 * 100vw);
}

.filter_btn:hover,
.export_btn:hover {
    opacity: 0.7;
}

.search_input::placeholder,
.search_input {
    font-size: calc(16 / 1512 * 100vw);
}

.export_btn {
    padding: 0 calc(20 / 1512 * 100vw);
    font-size: calc(16 / 1512 * 100vw);
    height: calc(45 / 1512 * 100vw);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: calc(10 / 1512 * 100vw);
    background: #000;
    color: #fff;
    cursor: pointer;
    transition: 0.5s all;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0;
    font-size: calc(12 / 1512 * 100vw);
}

.table th,
.table td {
    border: calc(1 / 1512 * 100vw) solid #FFEAEA;
    padding: calc(12 / 1512 * 100vw);
    text-align: left;

}

.table th {
    background-color: #FFEAEA;
    font-weight: bold;
}

.table tr:hover {
    background-color: #dce6f542;
}

.table tr:nth-child(even) {
    background-color: #FFEAEA;
}

.delete_btn_container {
    background: #77000c;
    display: flex;
    align-items: center;
    border-radius: calc(8 / 1512 * 100vw);
    padding: calc(8 / 1512 * 100vw);
    justify-content: center;
    cursor: pointer;
    color: #fff;
}


.page_icons_grouped {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(10 / 1512 * 100vw);
    margin-top: calc(7 / 1512 * 100vw);
}

.angle_icon_container,
.angle_icon_container_inactive {
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    background: #000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.5s all;
}

.angle_icon_container_inactive {
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
}

.angle_icon_container:hover {
    opacity: 0.6;
}


.tooltip_container {
    position: relative;
    cursor: pointer;
}

.tooltip {
    position: absolute;
    opacity: 1;
    padding: calc(5 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    background: #5c5c5c;
    color: #fff;
    transition: 0.5s all;
    transform: scale(0);
    transform-origin: top left;
}

.tooltip_container:hover .tooltip {
    opacity: 1;
    transform: scale(1);
}

.item_text {
    width: calc(120 / 1280 * 100vw);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-size: calc(14 / 1512 * 100vw);
}

.drawer_sub_container {
    height: 100%;
    padding: calc(20 / 1512 * 100vw);
    background: #fff;
    width: 32%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.invite_intro_text {
    font-size: calc(20 / 1512 * 100vw);
    text-align: center;
    font-weight: bold;
}

.invite_intro_sub_text {
    font-size: calc(14 / 1512 * 100vw);
    margin-top: calc(5 / 1512 * 100vw);
    color: #5c5c5c;
    text-align: center;
}

.invite_input {
    width: 100%;
    height: calc(50 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    padding: calc(10 / 1512 * 100vw);
    border: calc(2 / 1512 * 100vw) solid #FFEAEA;
}

.invite_input_grouped {
    margin-top: calc(20 / 1512 * 100vw);
    display: flex;
    flex-direction: column;
    gap: calc(20 / 1512 * 100vw);
}

.submit_invite_user_btn {
    height: calc(45 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    color: #fff;
    cursor: pointer;
}

.submit_invite_user_btn:hover {
    opacity: 0.8;
}

.select {
    width: 100%;
    height: 100%;
    outline: none;
    background: none;
    border: none;
}

.outer_container {
    display: flex;
    align-items: flex-start;
    height: 100%;
    gap: calc(20 / 1512 * 100vw);
    width: 100%;
}

.showing {
    margin: calc(20 / 1512 * 100vw) 0;
    font-size: calc(16 / 1512 * 100vw);
}

.loader_con {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: calc(100 / 1512 * 100vw);
    margin-top: calc(10 / 1512 * 100vw);

}


.drawer_main_container,
.drawer_main_container_inactive {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #00000087;
    padding: calc(10 / 1512 * 100vw);
    display: flex;
    left: 0;
    top: 0;
    z-index: 500;
    justify-content: right;
    transition: 0.5s all;
    transition: 0.5s all;
}

.drawer_main_container_inactive {
    width: 0;
    padding: 0;
    overflow: hidden;
}

.close_modal_icon {
    margin-left: auto;
    margin-bottom: calc(20 / 1512 * 100vw);
    width: calc(20 / 1512 * 100vw);
    height: calc(20 / 1512 * 100vw);
    cursor: pointer;
    transition: 0.5s all;
}

.close_modal_icon:hover {
    color: red;
}


.click_here:hover {
    text-decoration: underline;
}

.close_modal_icon_ {
    display: none;
}

.actionContainer {
    display: flex;
    gap: calc(5 / 1280 * 100vw);
}

.action {
    background: #007bff;
    color: #fff;
    font-weight: bold;
    border-radius: calc(5 / 1512 * 100vw);
    padding: calc(5 / 1512 * 100vw) calc(10 / 1512 * 100vw);
    font-size: calc(12 / 1280 * 100vw);
    transition: 0.5s all;
}

.action:hover {
    opacity: 0.5;
}

.filtersItemContainer {
    display: flex;
    align-items: center;
    gap: calc(10 / 1512 * 100vw);
    margin-top: calc(10 / 1512 * 100vw)
}

.itemFilters {
    border: calc(1 / 1280 * 100vw) solid #fff;
    border-radius: calc(10 / 1280 * 100vw);
    padding: calc(2 / 1280 * 100vw) calc(10 / 1280 * 100vw);
    cursor: pointer;
    transition: 0.5s all;
    text-transform: capitalize;
    font-size: calc(14 / 1280 * 100vw);
    /* font-weight: bold; */
}

.itemFilters:hover {
    transform: scale(1.1);
}


@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.rotating {
    animation: rotate 1s linear infinite;
    width: calc(20 / 1512 * 100vw);
    height: calc(20 / 1512 * 100vw);
}

.rotating_inactive {
    width: calc(20 / 1512 * 100vw);
    height: calc(20 / 1512 * 100vw);
}


.refresh {
    display: flex;
    gap: calc(5 / 1512 * 100vw);
    align-items: center;
    cursor: pointer;
}

.refresh:hover {
    opacity: 0.4;
}

.drawer_container_grouped {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - calc(300 / 1512 * 100vw));
    margin-top: calc(30 / 1512 * 100vw);
    gap: calc(10 / 1512 * 100vw);
}

.drawer_container {
    padding: calc(10 / 1512 * 100vw) calc(20 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    background: #00000087;
    color: #fff;
    transition: 0.5s all;
    cursor: pointer;
    outline: none;
    border: none;
}

.drawer_container:hover {
    opacity: 0.5;
}

.title {
    font-size: calc(20 / 1512 * 100vw);
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: calc(10 / 1512 * 100vw);
    margin: calc(10 / 1512 * 100vw) 0;
    cursor: pointer;
    transition: 0.5s all;
}

.title:hover{
    opacity: 0.5;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.rotating {
    animation: rotate 1s linear infinite;
    width: calc(20 / 1512 * 100vw);
    height: calc(20 / 1512 * 100vw);
}

.rotating_inactive {
    width: calc(20 / 1512 * 100vw);
    height: calc(20 / 1512 * 100vw);
}


@media screen and (max-width: 480px) {
    .container {
        background: #fff;
        width: 100%;
        height: 100%;
        border-radius: calc(10 / 414 * 100vw);
        padding: calc(20 / 414 * 100vw) calc(10 / 414 * 100vw) calc(10 / 414 * 100vw) calc(10 / 414 * 100vw);
        display: flex;
        flex-direction: column;
        gap: calc(10 / 414 * 100vw);
    }

    .intro_text {
        font-size: calc(20 / 414 * 100vw);
        font-weight: bold;
        color: #000;
    }


    .intro_sub_text,
    .emphasis {
        font-size: calc(14 / 414 * 100vw);
        color: #939292;
        width: 90%;
        line-height: calc(18 / 414 * 100vw);
    }


    .publish_btn {
        padding: calc(10 / 414 * 100vw) calc(20 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        width: fit-content;
    }

    .empty_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: calc(100vh - calc(470 / 414 * 100vw));
    }

    .scrollable {
        height: calc(100vh - calc(450 / 414 * 100vw));
        overflow: auto;
        width: 100%;
    }

    .page_title_publish_grouped {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    .publish_btn {
        padding: calc(10 / 414 * 100vw);
        margin-top: calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        color: #fff;
        background: #000;
        margin-left: 0;
        cursor: pointer;
        transition: 0.5s all;
        font-size: calc(14 / 414 * 100vw);
    }

    .empty_image {
        width: calc(100 / 414 * 100vw);
    }



    .empty_message {
        font-size: calc(16 / 414 * 100vw);
        transition: 0.5s all;
        cursor: pointer;
        width: 70%;
        text-align: center;
    }

    .search_container {
        width: 100%;
        height: calc(45 / 414 * 100vw);
        display: flex;
        padding: calc(10 / 414 * 100vw) calc(15 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        gap: calc(5 / 414 * 100vw);
        border: solid calc(2 / 414 * 100vw) #FFEAEA;
        align-items: center;
    }

    .search_icon {
        width: calc(20 / 414 * 100vw);
        height: calc(20 / 414 * 100vw);
    }

    .search_input {
        width: 100%;
        height: 100%;
    }


    .top_section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: calc(10 / 414 * 100vw);
    }

    .filter_btn {
        height: calc(45 / 414 * 100vw);
        font-size: calc(16 / 414 * 100vw);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: calc(10 / 414 * 100vw);
        border: solid calc(2 / 414 * 100vw) #FFEAEA;
        /* border: solid calc(2 / 414 * 100vw) #000; */
        cursor: pointer;
        transition: 0.5s all;
        padding: calc(10 / 414 * 100vw);
    }

    .filter_btn:hover,
    .export_btn:hover {
        opacity: 0.7;
    }



    .export_btn {
        padding: 0 calc(20 / 414 * 100vw);
        font-size: calc(16 / 414 * 100vw);
        height: calc(45 / 414 * 100vw);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: calc(10 / 414 * 100vw);
        background: #000;
        color: #fff;
        cursor: pointer;
        transition: 0.5s all;
    }

    .table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 0;
        font-size: calc(14 / 414 * 100vw);
    }

    .table th,
    .table td {
        border: calc(1 / 414 * 100vw) solid #FFEAEA;
        padding: calc(8 / 414 * 100vw);
        text-align: left;
    }

    .table th {
        background-color: #FFEAEA;
        font-weight: bold;
        font-size: calc(14 / 414 * 100vw);
    }

    .table tr:hover {
        background-color: #dce6f542;
    }

    .table tr:nth-child(even) {
        background-color: #FFEAEA;
    }



    .invite_input_grouped {
        margin-top: calc(20 / 414 * 100vw);
        display: flex;
        flex-direction: column;
        gap: calc(20 / 414 * 100vw);
    }

    .submit_invite_user_btn {
        height: calc(45 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        display: flex;
        align-items: center;
        justify-content: center;
        background: #000;
        color: #fff;
        cursor: pointer;
    }

    .submit_invite_user_btn:hover {
        opacity: 0.8;
    }

    .select {
        width: 100%;
        height: 100%;
        outline: none;
        background: none;
        border: none;
    }

    .outer_container {
        display: flex;
        align-items: flex-start;
        height: 100%;
        gap: calc(20 / 414 * 100vw);
        width: 100%;
    }

    .showing {
        margin: calc(20 / 414 * 100vw) 0;
        font-size: calc(16 / 414 * 100vw);
    }

    .loader_con {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #000;
        border-radius: calc(20 / 414 * 100vw);
    }


    .drawer_main_container,
    .drawer_main_container_inactive {
        width: 100%;
        height: 100%;
        position: absolute;
        background: #00000087;
        padding: calc(10 / 414 * 100vw);
        display: flex;
        left: 0;
        top: 0;
        z-index: 500;
        justify-content: right;
        transition: 0.5s all;
        transition: 0.5s all;
    }

    .drawer_main_container_inactive {
        width: 0;
        padding: 0;
        overflow: hidden;
    }


    .close_modal_icon:hover {
        color: red;
    }


    .click_here:hover {
        text-decoration: underline;
    }

    .filter_btn {
        height: calc(45 / 414 * 100vw);
        font-size: calc(16 / 414 * 100vw);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: calc(10 / 414 * 100vw);
        border: solid calc(2 / 414 * 100vw) #FFEAEA;
        /* border: solid calc(2 / 414 * 100vw) #000; */
        cursor: pointer;
        transition: 0.5s all;
        padding: calc(10 / 414 * 100vw);
    }

    .filter_btn:hover,
    .export_btn:hover {
        opacity: 0.7;
    }

    .search_input::placeholder,
    .search_input {
        font-size: calc(14 / 414 * 100vw);
    }

    .export_btn {
        padding: 0 calc(20 / 414 * 100vw);
        font-size: calc(16 / 414 * 100vw);
        height: calc(45 / 414 * 100vw);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: calc(10 / 414 * 100vw);
        background: #000;
        color: #fff;
        cursor: pointer;
        transition: 0.5s all;
    }

    .table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 0;
        font-size: calc(14 / 414 * 100vw);
    }

    .table th,
    .table td {
        border: calc(1 / 414 * 100vw) solid #FFEAEA;
        padding: calc(8 / 414 * 100vw);
        text-align: left;
    }

    .table th {
        background-color: #FFEAEA;
        font-weight: bold;
    }

    .table tr:hover {
        background-color: #dce6f542;
    }

    .table tr:nth-child(even) {
        background-color: #FFEAEA;
    }


    .drawer_sub_container {
        height: 100%;
        padding: calc(20 / 414 * 100vw);
        background: #fff;
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .invite_intro_text {
        font-size: calc(20 / 414 * 100vw);
        text-align: center;
        font-weight: bold;
    }

    .invite_intro_sub_text {
        font-size: calc(14 / 414 * 100vw);
        margin-top: calc(5 / 414 * 100vw);
        color: #5c5c5c;
        text-align: center;
    }

    .invite_input {
        width: 100%;
        height: calc(50 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        padding: calc(10 / 414 * 100vw);
        border: calc(2 / 414 * 100vw) solid #FFEAEA;
    }

    .invite_input_grouped {
        margin-top: calc(20 / 414 * 100vw);
        display: flex;
        flex-direction: column;
        gap: calc(20 / 414 * 100vw);
    }

    .submit_invite_user_btn {
        height: calc(45 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        display: flex;
        align-items: center;
        justify-content: center;
        background: #000;
        color: #fff;
        cursor: pointer;
    }

    .submit_invite_user_btn:hover {
        opacity: 0.8;
    }

    .select {
        width: 100%;
        height: 100%;
        outline: none;
        background: none;
        border: none;
    }

    .outer_container {
        display: flex;
        align-items: flex-start;
        height: 100%;
        gap: calc(20 / 414 * 100vw);
        width: 100%;
    }

    .showing {
        margin: calc(20 / 414 * 100vw) 0;
        font-size: calc(16 / 414 * 100vw);
    }

    .loader_con {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #000;
        border-radius: calc(20 / 414 * 100vw);
    }


    .drawer_main_container,
    .drawer_main_container_inactive {
        width: 100%;
        height: 100%;
        position: absolute;
        background: #00000087;
        padding: calc(10 / 414 * 100vw);
        display: flex;
        left: 0;
        top: 0;
        z-index: 500;
        justify-content: right;
        transition: 0.5s all;
        transition: 0.5s all;
    }

    .drawer_main_container_inactive {
        width: 0;
        padding: 0;
        overflow: hidden;
    }

    .close_modal_icon {
        display: none;
    }



    .close_modal_icon_ {
        margin-left: auto;
        margin-bottom: calc(20 / 414 * 100vw);
        transition: 0.5s all;
        font-size: calc(18 / 414 * 100vw);
        display: flex;
    }

    .click_here:hover {
        text-decoration: underline;
    }


    .tooltip {
        padding: calc(5 / 414 * 100vw);
        border-radius: calc(5 / 414 * 100vw);
    }



    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    .rotating {
        animation: rotate 1s linear infinite;
        width: calc(20 / 414 * 100vw);
        height: calc(20 / 414 * 100vw);
    }

    .rotating_inactive {
        width: calc(20 / 414 * 100vw);
        height: calc(20 / 414 * 100vw);
    }

    .refresh {
        display: flex;
        gap: calc(5 / 414 * 100vw);
    }

    .drawer_container_grouped {
        height: calc(100vh - calc(300 / 414 * 100vw));
        margin-top: calc(30 / 414 * 100vw);
        gap: calc(20 / 414 * 100vw);
    }

    .drawer_container {
        padding: calc(20 / 414 * 100vw) calc(20 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
    }

    .item_text {
        font-size: calc(14 / 414 * 100vw);
    }

    .table_container {
        height: calc(100vh - calc(400 / 414 * 100vw));
        overflow: auto;
    }


    .actionContainer {
        display: flex;
        gap: calc(5 / 1280 * 100vw);
    }

    .action {
        background: #007bff;
        color: #fff;
        font-weight: bold;
        border-radius: calc(5 / 414 * 100vw);
        padding: calc(5 / 414 * 100vw) calc(10 / 414 * 100vw);
        transition: 0.5s all;
        font-size: calc(12 / 414 * 100vw);
    }

    .action:hover {
        opacity: 0.5;
    }

    .filtersItemContainer {
        display: flex;
        align-items: center;
        gap: calc(10 / 414 * 100vw);
        margin-top: calc(10 / 414 * 100vw)
    }

    .itemFilters {
        border: calc(1 / 414 * 100vw) solid #fff;
        border-radius: calc(10 / 414 * 100vw);
        padding: calc(2 / 414 * 100vw) calc(10 / 414 * 100vw);
        cursor: pointer;
        transition: 0.5s all;
        text-transform: capitalize;
        font-size: calc(12 / 414 * 100vw);
        /* font-weight: bold; */
    }

}