* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Satoshi', sans-serif !important;
}
.App {
  font-family: 'Satoshi', sans-serif !important;
  background-color: #F5F5F5;
  overflow-x: hidden;
  width: 100vw;
}


/* Scrollbar container */
::-webkit-scrollbar {
  width: calc(12 / 1512 * 100vw); /* Width of the scrollbar */
  height: calc(12 / 1512 * 100vw); /* Height of the scrollbar (for horizontal scrollbars) */
}

/* Track (the background of the scrollbar) */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light gray background for the track */
  border-radius: calc(10 / 1512 * 100vw); /* Rounded corners */
}

/* Thumb (the scroll indicator itself) */
::-webkit-scrollbar-thumb {
  background-color: #ffd19f; /* Darker gray thumb */
  border-radius: calc(10 / 1512 * 100vw); /* Rounded corners */
  border: calc(3 / 1512 * 100vw) solid #f1f1f1; /* Optional: makes the thumb smaller and gives it padding */
}

/* Hover effect on the thumb */
::-webkit-scrollbar-thumb:hover {
  background-color: #ffa347; /* Darker gray when hovering over the thumb */
}

/* Optional: Scrollbar corner (where horizontal and vertical scrollbars meet) */
::-webkit-scrollbar-corner {
  background-color: #f1f1f1;
}
