.container {
    height: calc(100vh - calc(190 / 1512 * 100vw));
    display: flex;
    flex-direction: column;
    gap: calc(10 / 1512 * 100vw);
    overflow: auto;
}

.nameAndDescriptionContainer {
    width: 80%;
}

.detailsSection {
    display: flex;
    align-items: center;
    background: #f4f4f4;
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    gap: calc(10 / 1512 * 100vw);
}

.img {
    width: calc(100 / 1512 * 100vw);
    height: calc(100 / 1512 * 100vw);
    object-fit: contain;
    border-radius: calc(10 / 1512 * 100vw);
    padding: calc(10 / 1512 * 100vw);
    background: #fff;
}

.name {
    font-size: calc(18 / 1512 * 100vw);
    font-weight: bold;
    color: #909090;
    text-transform: capitalize;
}

.description {
    font-size: calc(14 / 1512 * 100vw);
    color: #909090;
}

.editInitiativeDetailsButton {
    margin-left: auto;
}

.editInitiativeText {
    padding: calc(5 / 1512 * 100vw) calc(20 / 1512 * 100vw);
    background: #f24401;
    color: #fff;
    font-size: calc(14 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    font-weight: bold;
    cursor: pointer;
    transition: 0.5s all;
}

.searchAndExport {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.searchContainer {
    display: flex;
    align-items: center;
    background: #f4f4f4;
    width: 88%;
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
}

.input {
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    outline: none;
}





.title {
    font-weight: bold;
    color: #818181;
    font-size: calc(16 / 1512 * 100vw);
    margin-top: calc(34 / 1512 * 100vw);
}

.addSKUBtn {
    font-size: calc(14 / 1512 * 100vw);
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    background: #909090;
    color: #fff;
    width: fit-content;
    font-weight: bold;
}

.groupedCards {
    display: flex;
    gap: calc(20 / 1512 * 100vw);
    flex-wrap: wrap;
}


.cardContainer {
    width: calc(190 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    border: calc(1 / 1512 * 100vw) solid #dddddd;
    padding: calc(10 / 1512 * 100vw);
}

.cardImage {
    background: #fff;
    width: 100%;
    height: calc(82 / 1512 * 100vw);
    background-position: center;
    background-size: cover;
    border-radius: calc(10 / 1512 * 100vw);
    background-repeat: no-repeat;
    padding: calc(10 / 1512 * 100vw);
    object-fit: contain;

}


.editInitiativeBrand {
    background: #55A5A5;
    padding: calc(2 / 1512 * 100vw) calc(5 / 1512 * 100vw);
    color: #fff;
    font-size: calc(12 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    font-weight: bold;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s all;
}

.cardName,
.cardSKU {
    font-size: calc(14 / 1512 * 100vw);
    font-weight: bold;
    color: #818181;
}

.cardSKU {
    font-weight: normal;
}



.editInitiativeText:hover,
.editInitiativeBrand:hover {
    opacity: 0.6;
}