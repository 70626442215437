.container {
    padding: calc(10 / 1512 * 100vw) calc(30 / 1512 * 100vw);
    display: flex;
    justify-content: space-between;
    background: #fff;
    font-size: calc(18 / 1512 * 100vw);
    font-weight: bold;
    align-items: center;
    gap: calc(50 / 1512 * 100vw);
    box-shadow: 0px 11px 24px -8px rgba(0, 0, 0, 0.6);
    width: 100%;
    background: #001a2a;
    color: #fff;
}

.activeScreenText {
    text-transform: capitalize;
    cursor: pointer;
    margin-right: auto;

}


.avatarContainer {
    display: flex;
    gap: calc(5 / 1512 * 100vw);
    align-items: center;
}

.avatar {
    width: calc(50 / 1512 * 100vw);
    height: calc(50 / 1512 * 100vw);
}


.name {
    font-size: calc(16 / 1512 * 100vw);
    font-weight: normal;
    cursor: pointer;

}

.role {
    font-size: calc(14 / 1512 * 100vw);
    color: rgb(184, 184, 184);
    font-weight: normal;
    cursor: pointer;

}

.bellIcon {
    width: calc(25 / 1512 * 100vw);
    height: calc(25 / 1512 * 100vw);
    cursor: pointer;

}

.dropDownGroup {
    display: flex;
    position: relative;
    align-items: center;
}


.flagImage {
    width: calc(30 / 1512 * 100vw);
    margin-right: calc(5 / 1512 * 100vw);
    object-fit: contain;
}

.countryCode {
    font-size: calc(14 / 1512 * 100vw);
    font-weight: bold;
}

.flexCountry {
    display: flex;
    align-items: center;
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    cursor: pointer;
    transition: 0.5s all;
}

.flexCountry:hover {
    background: #e7e7e7;
}

.dropDownSubGroup {
    display: flex;
    align-items: center;
}

.countriesList {
    background: #fff;
    position: absolute;
    z-index: 10;
    border-radius: calc(10 / 1512 * 100vw);
    top: calc(30 / 1512 * 100vw);
    right: 0;
    color: #000;
    width: calc(200 / 1512 * 100vw);
    flex-direction: column;
    display: flex;
    gap: calc(5 / 1512 * 100vw);
    box-shadow: 0px 11px 24px -8px rgba(0, 0, 0, 0.6);
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    padding: 0;
}

.dropDownSubGroup:hover+.countriesList,
.countriesList:hover {
    max-height: calc(500 / 1512 * 100vw);
    padding: calc(10 / 1512 * 100vw);
    background: #fff;
    overflow: visible;
    overflow: auto;
}