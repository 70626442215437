.topContainer {
    width: 100%;
    display: flex;
    gap: calc(10 / 1512 * 100vw);
    margin-bottom: calc(7 / 1512 * 100vw);
    /* justify-content: space-between; */
    flex-wrap: wrap;
}

.input {
    outline: none;
    border: none;
    background: none;
    width: 100%;
    height: 100%;
}

.inputContainer,
.smallInputContainer,
.dateContainer,
.selectContainer {
    width: calc(470 / 1512 * 100vw);
    height: calc(48 / 1512 * 100vw);
    display: flex;
    gap: calc(10 / 1512 * 100vw);
    padding: calc(14 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    border: solid calc(1.5 / 1512 * 100vw) #002a3f4d;
    align-items: center;
}



.inputContainer{
    width: 60%;
}

.smallInputContainer{
    width: 50%;
}

.selectContainer {
    width: calc(150 / 1512 * 100vw);
}

.dateContainer {
    width: calc(180 / 1512 * 100vw);
}

.searchIcon {
    width: calc(20 / 1512 * 100vw);
    height: calc(20 / 1512 * 100vw);
}

.input,
.input::placeholder {
    font-size: calc(14 / 1512 * 100vw);
    color: #818181;
}

.dateGrouped {
    display: flex;
    gap: calc(5 / 1512 * 100vw);
    align-items: center;
    color: #909090;
    font-weight: bold;
}

.exportButton {
    width: calc(80 / 1512 * 100vw);
    /* height: calc(48 / 1512 * 100vw); */
    height: 40px;
    background-color: #707070;
    color: #fff;
    font-size: calc(16 / 1512 * 100vw);
    /* border-radius: calc(10 / 1512 * 100vw); */
    border-radius: 4px;
    transition: 0.5s all;
}

.secondContainer {
    width: 100%;
    display: flex;
    gap: calc(10 / 1512 * 100vw);
    margin-bottom: calc(7 / 1512 * 100vw);
}


.clearFilter {
    width: 174px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 14px;
    border-radius: 10px;
    background-color: #ffa347;
    color: #fff;
    transition: 0.5s all;
    cursor: pointer;
}