.container {
    position: absolute;
    z-index: 10000;
    right: calc(20 / 1280 * 100vw);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: calc(20 / 1280 * 100vw);
    bottom: 20px;
}



.icon_container {
    background-color: rgb(2, 157, 4);
    width: calc(70 / 1280 * 100vw);
    height: calc(70 / 1280 * 100vw);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s all;
    cursor: pointer;
}

.icon_container:hover {
    opacity: 0.8;
}

.chat_icon,
.chat_icon_bland {
    color: #fff;
    width: calc(40 / 1280 * 100vw);
    height: calc(40 / 1280 * 100vw);
    opacity: 1;
    position: absolute;
    transition: 0.5s all;
}

.chat_icon_bland {
    opacity: 0;
}

.chat_arena,
.chat_arena_bland {
    display: flex;
    flex-direction: column;
    transition: 0.5s all;
    transform: scale(1);
    transform-origin: bottom right;
}

.chat_arena_bland {
    transform: scale(0);
    width: 0;
    padding: 0;
    height: 0;
    box-shadow: none;
    border-radius: 0;
}

.instruction_area {
    background: #4e4f4e;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: calc(20 / 1280 * 100vw) calc(10 / 1280 * 100vw);
    border-radius: calc(10 / 1280 * 100vw);
    display: flex;
    width: 100%;
    align-items: center;
    gap: calc(10 / 1280 * 100vw);
}

.action_icon_container {
    position: relative;
    width: calc(50 / 1280 * 100vw);
}

.action_icon_container:hover .tooltip {
    opacity: 1;
}

.tooltip {
    background: #fff;
    position: absolute;
    opacity: 0;
    transition: 0.5s all;
    padding: calc(5 / 1280 * 100vw);
    font-size: calc(12 / 1280 * 100vw);
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: calc(5 / 1280 * 100vw);
}

.icon {
    width: calc(20 / 1280 * 100vw);
    height: calc(20 / 1280 * 100vw);
    color: #fff;
}

.grouped_online {
    display: flex;
    gap: calc(2 / 1280 * 100vw);
    font-size: calc(14 / 1280 * 100vw);
    align-items: center;
    text-transform: lowercase;
    color: #fff;
}

.admin_icon {
    width: calc(30 / 1280 * 100vw);
    height: calc(30 / 1280 * 100vw);
    margin-left: calc(-15 / 1280 * 100vw);
}

.chat_with_title {
    font-size: calc(14 / 1280 * 100vw);
    font-weight: bold;
    color: #fff;
    width: 50%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: capitalize;
}

.online_indicator {
    width: calc(10 / 1280 * 100vw);
    height: calc(10 / 1280 * 100vw);
    background: rgb(178, 253, 92);
    border-radius: 100%;
}

.online_indicator_grouped {
    display: flex;
    align-items: center;
    gap: calc(5 / 1280 * 100vw);
}

.chat_side {
    width: 100%;
    height: 100%;
    background: 'red';
    margin-top: calc(20 / 1280 * 100vw);
    gap: calc(5 / 1280 * 100vw);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    gap: calc(15 / 1280 * 100vw);
    background: 'red';

}

.main_chat {
    display: flex;
    flex-direction: column;
    gap: calc(3 / 1280 * 100vw);
}

.input_container {
    background: #fff;
    border-top: 1px solid rgb(235, 234, 234);
    margin-top: auto;
    padding: calc(10 / 1280 * 100vw) 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: calc(5 / 1280 * 100vw);
    margin-top: auto;
}

.input {
    border: none;
    outline: none;
    font-size: calc(12 / 1280 * 100vw);
    width: 100%;
}

.input::placeholder {
    color: rgb(193, 190, 190);
    font-size: calc(12 / 1280 * 100vw);
}

.send_icon_bg_opa,
.send_icon_bg {
    width: calc(40 / 1280 * 100vw);
    height: calc(30 / 1280 * 100vw);
    opacity: 1;
    transition: 0.5s all;
    background-color: #4e4f4e;
    border-radius: calc(10 / 1280 * 100vw);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
}

.send_icon_bg_opa {
    opacity: 0.2;
    cursor: not-allowed;
}

.send_icon_bg:hover {
    opacity: 0.9;
}

.send_icon {
    width: calc(10 / 1280 * 100vw);
    height: calc(10 / 1280 * 100vw);
}

.admin_side_clickable_chat_container {
    display: flex;
}


.client_side_chat_container,
.admin_side_chat_container {
    display: flex;
    margin-left: auto;
    overflow-x: wrap;
    width: fit-content;
    max-width: calc(230 / 1280 * 100vw);
    align-items: flex-end;
    justify-content: flex-end;
    background: #FFEAEA;
    padding: calc(5 / 1280 * 100vw) calc(10 / 1280 * 100vw);
    border-radius: calc(10 / 1280 * 100vw);
    color: #F97066;
    font-size: calc(14 / 1280 * 100vw);
    gap: 0;
    font-weight: bold;
    flex-direction: column;
}

.admin_side_chat_container {
    margin-left: 0;
    background: rgb(78, 79, 78);
    align-items: flex-start;
    color: #fff
}

.unread_messages_date_container {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.unread_count {
    background: #4e4f4e;
    color: white;
    border-radius: 100%;
    width: calc(17 / 1280 * 100vw);
    height: calc(17 / 1280 * 100vw);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(14 / 1280 * 100vw);
    font-weight: bold;
    font-size: calc(10 / 1280 * 100vw);

}

.cancel_button {
    width: calc(35 / 1280 * 100vw);
    height: calc(35 / 1280 * 100vw);
    color: #4e4f4e;
    transition: 0.5s all;
    cursor: pointer;
}

.cancel_button:hover {
    color: hsla(120, 1%, 31%, 0.5);

}

.cancel_button_con {
    width: calc(400 / 1280 * 100vw);
    display: flex;
    justify-content: flex-end;
}


.sub_container {
    width: 100%;
    background: #ffffff8e;
    overflow: auto;
    padding: calc(20 / 1280 * 100vw);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    transition: 0.5s all;
    opacity: 1;
}

.names_arena,
.inst {
    width: calc(250 / 1280 * 100vw);
    overflow-y: auto;
    background: #fff;
    padding: calc(10 / 1280 * 100vw);
    border-radius: calc(20 / 1280 * 100vw);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-left: calc(20 / 1280 * 100vw);
    overflow: auto;
    height: calc(400 / 640 * 100vh);
}


.inst {
    width: calc(400 / 1280 * 100vw);
    display: flex;
    flex-direction: column;
}

.time_stamp {
    font-size: calc(10 / 1280 * 100vw);
    color: rgb(222, 222, 222);
}

.sub_con {
    display: flex;
    width: 100%;
    align-items: flex-end;
}

.date_header {
    font-size: calc(12 / 1280 * 100vw);
    text-align: center;
    font-family: "Comic Neue", cursive;
    font-style: normal;
}


.search_sub_container {
    display: flex;
    background: rgb(242, 242, 242);
    padding: calc(5 / 1280 * 100vw);
    border-radius: calc(5 / 1280 * 100vw);
    align-items: center;
}

.search_icon {
    width: calc(15 / 1280 * 100vw);
    height: calc(15 / 1280 * 100vw);
    margin-right: calc(5 / 1280 * 100vw);

}

.search,
.search::placeholder {
    background: none;
    width: 100%;
    outline: none;
    border: none;
    font-size: calc(14 / 1280 * 100vw);
}

.fullName {
    font-size: calc(12 / 1280 * 100vw);
    font-weight: bold;
    width: 70%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.users_container_active,
.users_container {
    display: flex;
    align-items: flex-start;
    padding: calc(10 / 1280 * 100vw);
    background: #f4f4f4;
    border-radius: calc(10 / 1280 * 100vw);
}

.users_container_active {
    background: #4e4f4e;
    color: #fff;
}

.all_users_containers {
    margin-top: calc(10 / 1280 * 100vw);
    gap: calc(10 / 1280 * 100vw);
    display: flex;
    flex-direction: column;

}

.timestamp_users {
    font-size: calc(12 / 1280 * 100vw);
}

.from_user {
    font-size: calc(10 / 1280 * 100vw);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.user_msg {
    margin-top: calc(5 / 1280 * 100vw);
    font-size: calc(12 / 1280 * 100vw);
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* Limit the text to 2 lines */
    line-clamp: 2;
    text-overflow: ellipsis;
    height: calc(40 / 1280 * 100vw);
    /* Adjust height to match the line height * number of lines */
}

.empty_image {
    width: calc(100 / 1280 * 100vw);
    height: calc(100 / 1280 * 100vw);
    font-size: calc(12 / 1280 * 100vw);
    margin-top: calc(30 / 1280 * 100vw);
}

.empty_msg_container {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: calc(16 / 1280 * 100vw);
    align-items: center;
}

._unread_count {
    font-size: calc(10 / 1280 * 100vw);
    color: #fff;
    background: #F97066;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: calc(20 / 1280 * 100vw);
    height: calc(20 / 1280 * 100vw);
}

.closeConversation_container {
    padding: calc(5 / 1280 * 100vw);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.closeConversation,
.disabledCloseConversation {
    background: rgb(127, 8, 8);
    color: #fff;
    display: flex;
    align-items: center;
    font-size: calc(14 / 1280 * 100vw);
    border-radius: calc(5 / 1280 * 100vw);
    justify-content: center;
    transition: 0.5s all;
    width: 49%;
}

.closeConversation:hover,
.disabledCloseConversation {
    opacity: 0.3;
}


.call_container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #4e4f4e67;
    width: 100vw;
    z-index: 20;
    color: #fff;
    height: 100%;
}



.image_previewer_container,
.image_previewer_container_inactive {
    background: #0000008d;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    flex-direction: column;
    gap: calc(5 / 1280 * 100vw);
    padding: calc(20 / 1280 * 100vw);
    border-radius: calc(20 / 1280 * 100vw);
    transition: 0.5s all;
    transform-origin: bottom right;
    z-index: 1000;
}


.progress_bar_container {
    width: calc(50 / 1280 * 100vw);
    background: #00000056;
    height: calc(50 / 1280 * 100vw);
    padding: calc(10 / 1280 * 100vw);
    border-radius: calc(10 / 1280 * 100vw);
}

.image_previewer_container_inactive {
    transform: scale(0);
    overflow: hidden;
    border: 0;
}


.image_container {
    width: 100%;
    height: 50%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload_btn_grouped {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(10 / 1280 * 100vw);
    width: 100%;
}

.send {
    padding: calc(5 / 1280 * 100vw) calc(10 / 1280 * 100vw);
    border-radius: calc(10 / 1280 * 100vw);
    font-size: calc(12 / 1280 * 100vw);
    background: #F97066;
    cursor: pointer;
    font-weight: bold;
}


.send:hover {
    opacity: 0.9;
}


.image_message_con {
    display: flex;
    gap: calc(2 / 1280 * 100vw);
    align-items: center;
}

.image_message {
    width: calc(15 / 1280 * 100vw);
    height: calc(15 / 1280 * 100vw);
}

.image_message_text {
    font-size: calc(12 / 1280 * 100vw);
}



.image_upload_icon {
    width: calc(20 / 1280 * 100vw);
    height: calc(20 / 1280 * 100vw);
}


.call_big {
    position: fixed;

}


.textAreaContainer {
    width: 100%;
    border: calc(2 / 1280 * 100vw) solid #4e4f4e;
    border-radius: calc(5 / 1280 * 100vw);
    overflow: hidden;
}


.actionsGrouped {
    margin-top: calc(5 / 1280 * 100vw);
}

.textArea {
    width: 100%;
    resize: none;
    height: calc(30 / 1280 * 100vw);
    padding: calc(5 / 1280 * 100vw);
    font-size: calc(14 / 1280 * 100vw);
    border: none;
    outline: none;
}




@media screen and (max-width: 999px) {

    .container {
        position: absolute;
        z-index: 1000;
        bottom: 20px;
        right: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 20px;
    }

    .icon_container {
        width: 70px;
        height: 70px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.5s all;
        cursor: pointer;
    }



    .chat_icon,
    .chat_icon_bland {
        color: #fff;
        width: 40px;
        height: 40px;
        opacity: 1;
        position: absolute;
        transition: 0.5s all;
    }

    .chat_icon_bland {
        opacity: 0;
    }

    .chat_arena,
    .chat_arena_bland {
        display: flex;
        flex-direction: column;
        transition: 0.5s all;
        transform: scale(1);
        transform-origin: bottom right;
        width: 100%;
        padding-left: 20px;
        position: relative;
    }

    .chat_arena_bland {
        transform: scale(0);
        width: 0;
        padding: 0;
        height: 0;
        box-shadow: none;
        border-radius: 0;
    }

    .instruction_area {
        background: #4e4f4e;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 20px 10px;
        border-radius: 10px;
        display: flex;
        width: 100%;
        align-items: center;
        gap: 10px;
    }

    .action_icon_container {
        position: relative;
        width: 50px;
    }

    .action_icon_container:hover .tooltip {
        opacity: 1;
    }

    .tooltip {
        background: #fff;
        position: absolute;
        opacity: 0;
        transition: 0.5s all;
        padding: 5px;
        font-size: 12px;
        font-weight: bold;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
    }

    .icon {
        width: 20px;
        height: 20px;
        color: #fff;
    }

    .grouped_online {
        display: flex;
        gap: 2px;
        font-size: 14px;
        align-items: center;
        text-transform: lowercase;
        color: #fff;
    }

    .admin_icon {
        width: 30px;
        height: 30px;
        margin-left: -15px;
    }

    .chat_with_title {
        font-size: 14px;
        font-weight: bold;
        color: #fff;
        width: 50%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-transform: capitalize;
    }

    .online_indicator {
        width: 10px;
        height: 10px;
        background: rgb(178, 253, 92);
        border-radius: 100%;
    }

    .online_indicator_grouped {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .chat_side {
        width: 100%;
        height: 350px;
        margin-top: 20px;
        gap: 5px;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        gap: 15px;
    }

    .main_chat {
        display: flex;
        flex-direction: column;
        gap: 3px;
    }

    .input_container {
        background: #fff;
        border-top: 1px solid rgb(235, 234, 234);
        margin-top: auto;
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 5px;
    }

    .input {
        border: none;
        outline: none;
        font-size: 12px;
        width: 100%;
    }

    .input::placeholder {
        color: rgb(193, 190, 190);
        font-size: 12px;
    }

    .send_icon_bg_opa,
    .send_icon_bg {
        width: 40px;
        height: 30px;
        opacity: 1;
        transition: 0.5s all;
        background-color: #4e4f4e;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .send_icon_bg_opa {
        opacity: 0.2;
        cursor: not-allowed;
    }

    .send_icon_bg:hover {
        background-color: initial;
    }

    .send_icon {
        width: 10px;
        height: 10px;
        color: #fff;
    }

    .admin_side_clickable_chat_container {
        display: flex;
        background: red;
    }

    .client_side_chat_container,
    .admin_side_chat_container {
        display: flex;
        margin-left: auto;
        overflow-x: wrap;
        width: fit-content;
        max-width: 230px;
        align-items: flex-end;
        justify-content: flex-end;
        background: #FFEAEA;
        padding: 5px 10px;
        border-radius: 10px;
        color: #F97066;
        font-size: 14px;
        gap: 5px;
        font-weight: bold;
    }

    .admin_side_chat_container {
        margin-left: 0;
        background: rgb(78, 79, 78);
        color: #fff;
    }

    .unread_messages_date_container {
        margin-left: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .unread_count {
        background: #4e4f4e;
        color: white;
        border-radius: 100%;
        width: 17px;
        height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: bold;
        font-size: 10px;
    }

    .cancel_button {
        width: 35px;
        height: 35px;
        color: #4e4f4e;
        transition: 0.5s all;
        cursor: pointer;
    }

    .cancel_button:hover {
        color: hsla(120, 1%, 31%, 0.5);
    }

    .cancel_button_con {
        width: 400px;
        display: flex;
        justify-content: flex-end;
    }

    .sub_container {
        width: 100%;
    }

    .names_arena,
    .inst {
        overflow-y: auto;
        background: #fff;
        padding: 10px;
        border-radius: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin-left: 20px;
        width: 100%;
    }

    .names_arena {
        min-height: 512px;
    }

    .inst {
        width: 100%;
        position: absolute;
        left: 2px;
        right: 2px;
    }

    .time_stamp {
        font-size: 10px;
    }

    .sub_con {
        display: flex;
        width: 100%;
    }

    .date_header {
        font-size: 12px;
        text-align: center;
        font-family: "Comic Neue", cursive;
        font-style: normal;
    }

    .search_sub_container {
        display: flex;
        background: rgb(242, 242, 242);
        padding: 5px;
        border-radius: 5px;
        align-items: center;
    }

    .search_icon {
        width: 15px;
        height: 15px;
        margin-right: 5px;
    }

    .search,
    .search::placeholder {
        background: none;
        width: 100%;
        outline: none;
        border: none;
        font-size: 14px;
    }

    .fullName {
        font-size: 12px;
        font-weight: bold;
        width: 70%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .users_container_active,
    .users_container {
        display: flex;
        align-items: flex-start;
        padding: 10px;
        background: #f4f4f4;
        border-radius: 10px;
    }

    .users_container_active {
        background: #4e4f4e;
        color: #fff;
    }

    .all_users_containers {
        margin-top: 10px;
        gap: 10px;
        display: flex;
        flex-direction: column;
    }

    .timestamp_users {
        font-size: 12px;
    }

    .from_user {
        font-size: 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .user_msg {
        margin-top: 5px;
        font-size: 12px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
    }

    .users_msg_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 70%;
    }

    .inbox_notif_icon {
        width: 40px;
        height: 40px;
        color: #4e4f4e;
    }

    .error {
        background: rgb(251, 177, 177);
        padding: 10px 5px;
        font-size: 12px;
        text-align: center;
        border-radius: 10px;
    }

    .message_area {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-height: 512px;
        padding: 10px 5px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background: #fff;
        width: 100%;
        overflow-y: auto;
    }

    .message_container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-height: 512px;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background: #fff;
    }

    .chat_sub_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;
    }

    .warning_area {
        display: flex;
        width: 100%;
        padding: 10px 5px;
        border-radius: 10px;
        background: #fef3c7;
        font-size: 14px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .warning {
        font-size: 14px;
        color: #4e4f4e;
        text-align: center;
        width: 100%;
    }

    .textArea {
        height: 30px;
        padding: 5px;
        font-size: 14px;
    }

    .textAreaContainer {
        width: 100%;
        border: 2px solid #4e4f4e;
        border-radius: 5px;
        overflow: hidden;
    }


    .actionsGrouped {
        margin-top: 5px;
    }


}

@media screen and (max-width: 540px) {
    .container {
        position: absolute;
        z-index: 1000;
        bottom: calc(20 / 414 * 100vw);
        right: calc(20 / 414 * 100vw);
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: calc(20 / 414 * 100vw);
        width: 100%;
    }

    .icon_container {
        width: calc(70 / 414 * 100vw);
        height: calc(70 / 414 * 100vw);
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.5s all;
        cursor: pointer;
    }


    .chat_icon,
    .chat_icon_bland {
        color: #fff;
        width: calc(40 / 414 * 100vw);
        height: calc(40 / 414 * 100vw);
        opacity: 1;
        position: absolute;
        transition: 0.5s all;
    }

    .chat_icon_bland {
        opacity: 0;
    }

    .chat_arena,
    .chat_arena_bland {
        display: flex;
        flex-direction: column;
        transition: 0.5s all;
        transform: scale(1);
        transform-origin: bottom right;
        width: 100%;
        padding-left: calc(20 / 414 * 100vw);
        position: relative;
    }

    .chat_arena_bland {
        transform: scale(0);
        width: 0;
        padding: 0;
        height: 0;
        box-shadow: none;
        border-radius: 0;
    }

    .instruction_area {
        background: #4e4f4e;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: calc(20 / 414 * 100vw) calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        display: flex;
        width: 100%;
        align-items: center;
        gap: calc(10 / 414 * 100vw);
    }

    .action_icon_container {
        position: relative;
        width: calc(50 / 414 * 100vw);
    }

    .action_icon_container:hover .tooltip {
        opacity: 1;
    }

    .tooltip {
        background: #fff;
        position: absolute;
        opacity: 0;
        transition: 0.5s all;
        padding: calc(5 / 414 * 100vw);
        font-size: calc(12 / 414 * 100vw);
        font-weight: bold;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: calc(5 / 414 * 100vw);
    }

    .icon {
        width: calc(20 / 414 * 100vw);
        height: calc(20 / 414 * 100vw);
        color: #fff;
    }

    .grouped_online {
        display: flex;
        gap: calc(2 / 414 * 100vw);
        font-size: calc(14 / 414 * 100vw);
        align-items: center;
        text-transform: lowercase;
        color: #fff;
    }

    .admin_icon {
        width: calc(30 / 414 * 100vw);
        height: calc(30 / 414 * 100vw);
        margin-left: calc(-15 / 414 * 100vw);
    }

    .chat_with_title {
        font-size: calc(14 / 414 * 100vw);
        font-weight: bold;
        color: #fff;
        width: 50%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-transform: capitalize;
    }

    .online_indicator {
        width: calc(10 / 414 * 100vw);
        height: calc(10 / 414 * 100vw);
        background: rgb(178, 253, 92);
        border-radius: 100%;
    }

    .online_indicator_grouped {
        display: flex;
        align-items: center;
        gap: calc(5 / 414 * 100vw);
    }

    .chat_side {
        width: 100%;
        height: calc(350 / 414 * 100vw);
        margin-top: calc(20 / 414 * 100vw);
        gap: calc(5 / 414 * 100vw);
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        gap: calc(15 / 414 * 100vw);

    }

    .main_chat {
        display: flex;
        flex-direction: column;
        gap: calc(3 / 414 * 100vw);
    }

    .input_container {
        background: #fff;
        border-top: 1px solid rgb(235, 234, 234);
        margin-top: auto;
        padding: calc(10 / 414 * 100vw) 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: calc(5 / 414 * 100vw);
    }

    .input {
        border: none;
        outline: none;
        font-size: calc(12 / 414 * 100vw);
        width: 100%;
    }

    .input::placeholder {
        color: rgb(193, 190, 190);
        font-size: calc(12 / 414 * 100vw);
    }

    .send_icon_bg_opa,
    .send_icon_bg {
        width: calc(40 / 414 * 100vw);
        height: calc(30 / 414 * 100vw);
        opacity: 1;
        transition: 0.5s all;
        background-color: #4e4f4e;
        border-radius: calc(10 / 414 * 100vw);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .send_icon_bg_opa {
        opacity: 0.2;
        cursor: not-allowed;
    }

    .send_icon_bg:hover {
        background-color: initial;
    }

    .send_icon {
        width: calc(10 / 414 * 100vw);
        height: calc(10 / 414 * 100vw);
        color: #fff;
    }

    .admin_side_clickable_chat_container {
        display: flex;
    }


    .client_side_chat_container,
    .admin_side_chat_container {
        display: flex;
        margin-left: auto;
        overflow-x: wrap;
        width: fit-content;
        max-width: calc(230 / 414 * 100vw);
        align-items: flex-end;
        justify-content: flex-end;
        background: #FFEAEA;
        padding: calc(5 / 414 * 100vw) calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        color: #F97066;
        font-size: calc(14 / 414 * 100vw);
        gap: calc(5 / 414 * 100vw);
        font-weight: bold;
    }

    .admin_side_chat_container {
        margin-left: 0;
        background: rgb(78, 79, 78);
        color: #fff
    }

    .unread_messages_date_container {
        margin-left: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .unread_count {
        background: #4e4f4e;
        color: white;
        border-radius: 100%;
        width: calc(17 / 414 * 100vw);
        height: calc(17 / 414 * 100vw);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: calc(14 / 414 * 100vw);
        font-weight: bold;
        font-size: calc(10 / 414 * 100vw);

    }

    .cancel_button {
        width: calc(35 / 414 * 100vw);
        height: calc(35 / 414 * 100vw);
        color: #4e4f4e;
        transition: 0.5s all;
        cursor: pointer;
    }

    .cancel_button:hover {
        color: hsla(120, 1%, 31%, 0.5);

    }

    .cancel_button_con {
        width: calc(400 / 414 * 100vw);
        display: flex;
        justify-content: flex-end;
    }


    .sub_container {
        width: 100%;
    }

    .names_arena,
    .inst {
        overflow-y: auto;
        background: #fff;
        padding: calc(10 / 414 * 100vw);
        border-radius: calc(20 / 414 * 100vw);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin-left: calc(20 / 414 * 100vw);
        width: 100%;

    }

    .names_arena {
        min-height: calc(512 / 414 * 100vw);
    }

    .inst {
        width: 100%;
        position: absolute;
        left: calc(2 / 1280 * 100vw);
        right: calc(2 / 1280 * 100vw);
    }

    .time_stamp {
        font-size: calc(10 / 414 * 100vw);
    }

    .sub_con {
        display: flex;
        width: 100%;
    }

    .date_header {
        font-size: calc(12 / 414 * 100vw);
        text-align: center;
        font-family: "Comic Neue", cursive;
        font-style: normal;
    }


    .search_sub_container {
        display: flex;
        background: rgb(242, 242, 242);
        padding: calc(5 / 414 * 100vw);
        border-radius: calc(5 / 414 * 100vw);
        align-items: center;
    }

    .search_icon {
        width: calc(15 / 414 * 100vw);
        height: calc(15 / 414 * 100vw);
        margin-right: calc(5 / 414 * 100vw);

    }

    .search,
    .search::placeholder {
        background: none;
        width: 100%;
        outline: none;
        border: none;
        font-size: calc(14 / 414 * 100vw);
    }

    .fullName {
        font-size: calc(12 / 414 * 100vw);
        font-weight: bold;
        width: 70%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .users_container_active,
    .users_container {
        display: flex;
        align-items: flex-start;
        padding: calc(10 / 414 * 100vw);
        background: #f4f4f4;
        border-radius: calc(10 / 414 * 100vw);
    }

    .users_container_active {
        background: #4e4f4e;
        color: #fff;
    }

    .all_users_containers {
        margin-top: calc(10 / 414 * 100vw);
        gap: calc(10 / 414 * 100vw);
        display: flex;
        flex-direction: column;

    }

    .timestamp_users {
        font-size: calc(12 / 414 * 100vw);
    }

    .from_user {
        font-size: calc(10 / 414 * 100vw);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .user_msg {
        margin-top: calc(5 / 414 * 100vw);
        font-size: calc(12 / 414 * 100vw);
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        /* Limit the text to 2 lines */
        line-clamp: 2;
        text-overflow: ellipsis;
        height: calc(40 / 414 * 100vw);
        /* Adjust height to match the line height * number of lines */
    }

    .empty_image {
        width: calc(100 / 414 * 100vw);
        height: calc(100 / 414 * 100vw);
        font-size: calc(12 / 414 * 100vw);
        margin-top: calc(30 / 414 * 100vw);
    }

    .empty_msg_container {
        display: flex;
        flex-direction: column;
        font-weight: bold;
        font-size: calc(16 / 414 * 100vw);
        align-items: center;
    }

    ._unread_count {
        font-size: calc(10 / 414 * 100vw);
        color: #fff;
        background: #F97066;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        width: calc(20 / 414 * 100vw);
        height: calc(20 / 414 * 100vw);
    }

    .closeConversation_container {
        padding: calc(10 / 414 * 100vw);
        cursor: pointer;
    }

    .closeConversation {
        background: #4e4f4e;
        color: #fff;
        display: flex;
        align-items: center;
        font-size: calc(14 / 414 * 100vw);
        border-radius: calc(5 / 414 * 100vw);
        justify-content: center;
        transition: 0.5s all;
    }


    .call_container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background: #4e4f4e67;
        width: 100vw;
        z-index: 20;
        color: #fff;
        height: 100%;
    }



    .image_previewer_container,
    .image_previewer_container_inactive {
        background: #0000008d;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        flex-direction: column;
        gap: calc(5 / 414 * 100vw);
        padding: calc(20 / 414 * 100vw);
        border-radius: calc(20 / 414 * 100vw);
        transition: 0.5s all;
        transform-origin: bottom right;
        z-index: 1000;
    }


    .progress_bar_container {
        width: calc(50 / 414 * 100vw);
        background: #00000056;
        height: calc(50 / 414 * 100vw);
        padding: calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
    }

    .image_previewer_container_inactive {
        transform: scale(0);
        overflow: hidden;
        border: 0;
    }


    .image_container {
        width: 100%;
        height: 50%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .upload_btn_grouped {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: calc(10 / 414 * 100vw);
        width: 100%;
    }

    .send {
        padding: calc(5 / 414 * 100vw) calc(10 / 414 * 100vw);
        border-radius: calc(10 / 414 * 100vw);
        font-size: calc(12 / 414 * 100vw);
        background: #F97066;
        cursor: pointer;
        font-weight: bold;
    }


    .send:hover {
        opacity: 0.9;
    }


    .image_message_con {
        display: flex;
        gap: calc(2 / 414 * 100vw);
        align-items: center;
    }

    .image_message {
        width: calc(15 / 414 * 100vw);
        height: calc(15 / 414 * 100vw);
    }

    .image_message_text {
        font-size: calc(12 / 414 * 100vw);
    }



    .image_upload_icon {
        width: calc(20 / 414 * 100vw);
        height: calc(20 / 414 * 100vw);
    }


    .textArea {
        height: calc(30 / 414 * 100vw);
        padding: calc(5 / 414 * 100vw);
        font-size: calc(14 / 414 * 100vw);
    }

    .textAreaContainer {
        width: 100%;
        border: calc(2 / 414 * 100vw) solid #4e4f4e;
        border-radius: calc(5 / 414 * 100vw);
        overflow: hidden;
    }


    .actionsGrouped {
        margin-top: calc(5 / 414 * 100vw);
    }

    .closeConversation_container {
        padding: calc(5 / 414 * 100vw);
    }

    .closeConversation,
    .disabledCloseConversation {
        background: rgb(127, 8, 8);
        font-size: calc(14 / 414 * 100vw);
        border-radius: calc(5 / 414 * 100vw);
        width: 49%;
    }


}