.container {
    width: 100%;
    overflow: hidden;
}

.messagesContainer {
    width: 100%;
}

.topContainer {
    width: 100%;
    display: flex;
    gap: calc(10 / 1512 * 100vw);
    justify-content: space-between;
}

.secondContainer {
    width: 100%;
    display: flex;
    gap: calc(10 / 1512 * 100vw);
    margin-top: calc(7 / 1512 * 100vw);
}

.input {
    outline: none;
    border: none;
    background: none;
    width: 100%;
    height: 100%;
}

.inputContainer,
.dateContainer {
    width: calc(470 / 1512 * 100vw);
    height: calc(48 / 1512 * 100vw);
    display: flex;
    gap: calc(10 / 1512 * 100vw);
    padding: calc(14 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    border: solid calc(1.5 / 1512 * 100vw) #dbdbdb;
    align-items: center;
}

.dateContainer {
    width: calc(180 / 1512 * 100vw);
}

.searchIcon {
    width: calc(20 / 1512 * 100vw);
    height: calc(20 / 1512 * 100vw);
}

.input,
.input::placeholder {
    font-size: calc(14 / 1512 * 100vw);
    color: #818181;
}

.dateGrouped {
    display: flex;
    gap: calc(5 / 1512 * 100vw);
    align-items: center;
    color: #909090;
    font-weight: bold;
}

.exportButton {
    width: calc(80 / 1512 * 100vw);
    height: calc(48 / 1512 * 100vw);
    background-color: rgba(0, 0, 0, 0.56);
    color: #fff;
    font-size: calc(16 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    transition: 0.5s all;
}





.clearFilter {
    width: 174px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 14px;
    border-radius: 10px;
    background-color: #F24401;
    color: #fff;
    transition: 0.5s all;
    cursor: pointer;
}



.titleAndInstructionContainer {
    color: #818181;
    font-size: calc(16 / 1512 * 100vw);
    margin-top: calc(34 / 1512 * 100vw);
    margin-bottom: calc(24 / 1512 * 100vw);
}

.title {
    font-weight: bold;
}

.table_container {
    width: 100%;
}

.scrollable {
    height: calc(100vh - calc(450 / 1512 * 100vw));
    overflow: auto;
    width: 100%;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
}

.showing {
    margin: calc(20 / 1512 * 100vw) 0;
    font-size: calc(16 / 1512 * 100vw);
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 0;
    font-size: calc(12 / 1512 * 100vw);
}

.table th,
.table td {
    border: calc(1 / 1512 * 100vw) solid #dce6f5;
    padding: calc(8 / 1512 * 100vw);
    text-align: left;

}

.table th {
    background-color: #001a2ac8;
    font-weight: bold;
    color: #fff;
}

.table tr:hover {
    background-color: #dce6f542;
}

.scrollableContainer {
    width: 100%;
}



.table tr:nth-child(even) {
    background-color: rgb(237, 237, 237);
}




.tooltip_container {
    position: relative;
    cursor: pointer;
}

.tooltip {
    position: absolute;
    opacity: 1;
    padding: calc(5 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    background: #5c5c5c;
    color: #fff;
    transition: 0.5s all;
    transform: scale(0);
    width: calc(300 / 1512 * 100vw);
    z-index: 100;
}

.tooltip_container:hover .tooltip {
    opacity: 1;
    transform: scale(1);
}

.item_text {
    width: calc(100 / 1512 * 100vw);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-size: calc(14 / 1512 * 100vw);
}



.loader_con {
    width: 100%;
    display: flex;
    justify-content: center;
    height: calc(100vh - calc(565 / 1512 * 100vw));
    align-items: center;
}




.empty_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - calc(565 / 1512 * 100vw));
}

.empty_message {
    font-size: calc(18 / 1512 * 100vw);
    width: 100%;
    text-align: center;
    width: 40%;
    font-weight: 500;
    color: #001a2a;
}

.empty_image {
    width: calc(200 / 1512 * 100vw);
}


.image {
    width: 100%;
    height: calc(250 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    background: #F2F2F2;
    object-fit: cover;
}

.modalContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: calc(14 / 1512 * 100vw);
    height: calc(100vh - calc(50 / 1512 * 100vw));
    overflow: auto;
}

.modalTitleAndDateContainer,
.modalStoreAddressContainer,
.modalTitleAndDescriptionContainer {
    display: flex;
    padding: calc(14 / 1512 * 100vw) calc(10 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    background-color: #001a2a;
    flex-direction: column;
    color: #fff;
}

.modalTitle {
    font-weight: bold;
    font-size: calc(16 / 1512 * 100vw);
}

.modalDate {
    font-size: calc(14 / 1512 * 100vw);
}

.modalStoreAddress {
    font-size: calc(14 / 1512 * 100vw);
    font-weight: bold;
    width: 90%;
}

.modalStoreAddressContainer,
.modalTitleAndDescriptionContainer {
    flex-direction: row;
    background: #f2f2f2;
    font-weight: bold;
    color: #001a2a;
    align-items: center;
}


.modalIcon {
    width: calc(20 / 1512 * 100vw);
    height: calc(20 / 1512 * 100vw);
}

.modalTitleAndDescriptionContainer {
    height: calc(180 / 1512 * 100vw);
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.messageTitle {
    font-weight: bold;
    font-size: calc(16 / 1512 * 100vw);
}

.messageDescription {
    font-size: calc(14 / 1512 * 100vw);
    font-weight: normal;
}

.topButtonContainer {
    display: flex;
    gap: calc(10 / 1512 * 100vw);
    text-transform: capitalize;
    justify-content: flex-end;
}

.resolveButton,
.deleteButton {
    background: #2ea428;
    padding: calc(5 / 1512 * 100vw) calc(10 / 1512 * 100vw);
    color: #fff;
    border-radius: calc(5 / 1512 * 100vw);
    font-size: calc(14 / 1512 * 100vw);
    cursor: pointer;
    transition: 0.5s all;
}

.deleteButton {
    background: #9a0000;
}


.angle_icon_container:hover,
.clearFilter:hover,
.exportButton:hover,
.deleteButton:hover,
.resolveButton:hover {
    opacity: 0.8;
}