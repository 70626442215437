.container {
    width: 100%;
    height: calc(100vw - calc(812 / 1512 * 100vw));
    height: fit-content;
    display: flex;
    align-items: flex-start;
    gap: 1%;
}

.columnContainer{
    flex-direction: column;
    align-items: center;
    gap: calc(10  / 1512 * 100vw);
}

.firstContainer {
    background: #fff;
    height: 100%;
    width: 25%;
    padding: calc(10 / 1512 * 100vw);
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
    border-radius: calc(10 / 1512 * 100vw);
    position: sticky;
    top: 10px;
}

.firstFirstContainer {
    background: #ececec;
    width: 100%;
    height: 49%;
    padding: calc(15 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
}

.secondContainer {
    width: 50%;
    display: flex;
    justify-content: flex-start;
}

.barCon {
    height: 90%;
}

.barCon2 {
    height: calc(300 / 1512 * 100vw);
}

.containerTitle {
    font-size: calc(16 / 1512 * 100vw);
    /* margin: calc(10 / 1512 * 100vw) 0; */
    font-weight: bold;
}

.secondContainerCon {
    /* background: #ececec; */
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(10 / 1512 * 100vw);
    gap: calc(10 / 1512 * 100vw);
    justify-content: center;
    flex-direction: column;
}

.lastContainer {
    width: fit-content;
    justify-content: flex-start;
    gap: calc(10 / 1512 * 100vw);
    /* background: green; */
    /* width: 35%; */
}



.third {
    height: 30%;
    color: #fff;
    background: #000;
    height: fit-content;
    width: fit-content;
}

.fourth {
    width: 100%;
    height: 25%;
}

.daysSpent {
    font-size: calc(14 / 1512 * 100vw);
    color: #9F9FA0;
    /* Adjust the color to match your design */
}

.daysSpentFigure {
    font-size: calc(34 / 1512 * 100vw);
    font-weight: bold;
    color: #000;

}


.chartInfo {
    display: flex;
    gap: cal5 / 1512 * 100vw;
}

.gridContainer {
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(calc(120 / 1512 * 100vw), 1fr)); */
    gap: calc(16 / 1512 * 100vw);
    padding: calc(16 / 1512 * 100vw);
}

.gridItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ececec;
    background: #fff;
    /* Light grey background */
    border-radius: calc(10 / 1512 * 100vw);
    padding: calc(10 / 1512 * 100vw) calc(10 / 1512 * 100vw);
    text-align: center;
    height: calc(160 / 1512 * 100vw);
    box-shadow: 0px 11px 14px -8px rgba(0, 0, 0, 0.6);
    width: calc(300 / 1512 * 100vw);
}

.smallBox {
    background: #ececec;
    width: calc(140 / 1512 * 100vw);
    height: calc(154 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
}

.gridItem p {
    margin: 0;
    font-size: calc(15 / 1512 * 100vw);
    font-weight: bold;
    color: #333;
}

.span {
    font-size: calc(14 / 1512 * 100vw);
    font-weight: bold;
    color: #000;
}

.lab {
    width: 98%;
    text-align: center;
}

.arrow {
    margin-top: calc(10 / 1512 * 100vw);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.question2 {
    font-size: calc(12 / 1512 * 100vw);
    /* color: #333; */
    /* font-weight: bold; */
}

.creatorImageContainer {
    width: calc(40 / 1512 * 100vw);
    height: calc(40 / 1512 * 100vw);
}

.creatorImage {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.creatorContainer {
    display: flex;
    align-items: center;
    gap: calc(5 / 1512 * 100vw);
}

.creatorsGrouped {
    display: flex;
    flex-direction: column;
    gap: calc(30 / 1512 * 100vw);
    margin-top: calc(20 / 1512 * 100vw);
}

.creatorFullName {
    font-size: calc(14 / 1512 * 100vw);
    font-weight: bold;
    width: calc(200 / 1512 * 100vw);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.creatorEmail {
    font-size: calc(12 / 1512 * 100vw);
    font-weight: normal;
    color: #333;
}

.creatorCount {
    font-weight: bold;
    font-size: calc(14 / 1512 * 100vw);
    margin-right: auto;
    text-align: left;
}

.crown {
    width: calc(20 / 1512 * 100vw);
    height: calc(20 / 1512 * 100vw);
}

.creatorCountContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.questionTitle {
    font-size: calc(14 / 1512 * 100vw);
    margin: calc(15 / 1512 * 100vw) 0 calc(10 / 1512 * 100vw);
    font-weight: bold;
}

.loaderCon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.filterContainer {
    display: flex;
    /* background: #000;
    padding: calc(10 / 1512 * 100vw); */
    gap: calc(10 / 1512 * 100vw);
    /* border-radius: calc(10 / 1512  * 100vw); */
    /* color: #fff; */
    align-items: center;
    margin-bottom: calc(10 / 1512 * 100vw);
}

.selectContainer {
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    width: 40%;
    background: #fff;
    border: calc(2 / 1512 * 100vw) solid #000;
}

.select {
    width: 100%;
    height: 100%;
    outline: none;
    background: none;
    /* color: #fff; */
}

.searchBtn {
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(5 / 1512 * 100vw);
    background: #000;
    color: #fff;
    font-size: calc(18 / 1512 * 100vw);
    height: 100%;
}

.filterText {
    font-weight: bold;
    font-size: calc(14 / 1512 * 100vw);
    gap: calc(20 / 1512 * 100vw);
    max-width: 50%;
}

.appliedFilterContainer {
    margin-bottom: calc(20 / 1512 * 100vw);
    display: flex;
}

.resetBtn {
    font-size: calc(14 / 1512 * 100vw);
    margin-left: auto;
}

.dateTypePickerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: calc(10 / 1512 * 100vw) auto;
    gap: calc(10 / 1512 * 100vw);
    background: #000;
    width: fit-content;
    border-radius: calc(10 / 1512 * 100vw);
    color: #fff;
}

.btnPicker {
    padding: calc(10 / 1512 * 100vw);
    border-radius: calc(10 / 1512 * 100vw);
    box-shadow: 0px 11px 24px -8px rgba(0, 0, 0, 0.6);
}

.barMessage{
    font-size: calc(14  / 1512 * 100vw);
    font-weight: bold;
}

.noDataFoundText{
    font-weight: bold;
    font-size: calc(16 / 1512 * 100vw);
}