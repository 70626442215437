.drawer_main_container,
.drawer_main_container_inactive {
    width: 100vw;
    height: 100vh;
    position: absolute;
    background: #00000087;
    padding: calc(10 / 1512 * 100vw);
    display: flex;
    left: 0;
    top: 0;
    z-index: 500;
    justify-content: right;
    transition: 0.5s all;
    transition: 0.5s all;
}

.drawer_main_container_inactive {
    width: 0;
    padding: 0;
    overflow: hidden;
}

.drawer_sub_container {
    padding: calc(20 / 1512 * 100vw);
    background: #fff;
    width: 32%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - calc(15 / 1512 * 100vw));
    overflow-y: auto;
    /* justify-content: space-between; */
}

.cancelContainer {
    font-size: calc(16 / 1512 * 100vw);
    /* color: red; */
    font-weight: bold;
    transition: 0.5s all;
    cursor: pointer;
}

.cancelContainer:hover {
    opacity: 0.8s;
}

.cancelIcon {
    width: calc(25 / 1512 * 100vw);
    height: calc(25 / 1512 * 100vw);
    color: red;
    margin-left: auto;
    margin-bottom: calc(15 / 1512 * 100vw);
    transition: 0.5s all;
    cursor: pointer;
}

.cancelIcon:hover {
    transform: scale(1.5);
}